import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// Definisikan tipe untuk props
interface CKEditorComponentProps {
  data: string;
  onChange: (data: string) => void;
}

const CKEditorComponent: React.FC<CKEditorComponentProps> = ({
  data = "",
  onChange,
}) => {
  // Handler untuk event perubahan editor
  const handleEditorChange = (event: any, editor: any) => {
    const data = editor.getData();
    onChange(data);
  };

  return (
    <div>
      <CKEditor
        editor={ClassicEditor}
        data={data}
        onChange={handleEditorChange}
      />
    </div>
  );
};

export default CKEditorComponent;
