import * as XLSX from "xlsx";
import { Penduduk } from "../../../backend/master/penduduk/Controller";

export const exportToExcel = (data: Penduduk[], fileName: string): void => {
  // Define custom headers mapping
  const headers = {
    nama: "Nama",
    nik: "Nik",
    nokk: "No KK",
    jk: "Jenis Kelamin",
    tanggal_lahir: "Tempat, Tanggal Lahir",
    umur: "Umur",
    ke_akte: "Kepemilikan Akte Kelahiran",
    ke_ktp: "Kepemilikan KTP",
    agama: "Agama",
    pen_terakhir: "Pendidikan Terakhir (Tamat)",
    pend_saatini: "Pendidikan Saat Ini (yang sedang ditempuh)",
    jenis_pekerjaan: "Jenis Pekerjaan",
    kondisi_pekerjaan: "Kondisi pekerjaan",
    sumber_penghasilan: "Sumber Penghasilan",
    bansos: "Bansos yang diterima",
    bpjs_kt: "BPJS Ketenagakerjaan",
    status_perkawinan: "Status Perkawinan",
    tanggal_perkawinan: "Tanggal Perkawinan",
    akta_nikah: "Kepemilikan Akta Nikah",
    akta_cerai: "Kepemilikan Akta Cerai",
    kewarganegaraan: "Kewarganegaraan",
    nama_ayah: "Nama Ayah",
    nama_ibu: "Nama Ibu",
    golongan_darah: "Golongan Darah",
    alamat: "Alamat",
    rt_rw: "Rt/Rw",
    desa: "Desa",
    status_domisili: "Status Domisili",
    keterangan_domisili: "Keterangan Domisili",
    mutasi: "Mutasi",
    keterangan_mutasi: "Keterangan Mutasi",
    sebab_mutasi: "Sebab Mutasi",
    tanggal_mutasi: "Tanggal Mutasi",
    kondisi_kesehatan: "Kondisi Kesehatan",
    penyakit: "Penyakit yang diderita",
    disabilitas: "Disabilitas",
    yatim_piatu: "Yatim Piatu",
    bpjs_kes: "BPJS Kesehatan",
    status_rumah: "Status Rumah Tinggal",
    status_lahan: "Status Lahan Rumah Tinggal",
    layak_huni: "Layak/Tidak Layak Huni",
    penerangan: "Penerangan",
    sumber_air: "Sumber Air Bersih",
    jamban: "Jamban Sehat",
  };

  const transformedData = data.map((item) => {
    const formattedDate = new Date(item.tgl).toLocaleDateString("id-ID", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
    return {
      [headers.nama]: item.nama,
      [headers.nik]: item.nik,
      [headers.nokk]: item.no_urut,
      [headers.jk]: item.jenis === 1 ? "Laki-laki" : "Perempuan",
      [headers.tanggal_lahir]: `${item.tempat}, ${formattedDate}`,
      [headers.umur]: item.umur,
      [headers.ke_akte]: item.ke_akte === 1 ? "Ada" : "Tidak Ada",
      [headers.ke_ktp]: item.ke_ktp === "1" ? "Ada" : "Tidak Ada",
      [headers.agama]: item.agama,
      [headers.pen_terakhir]: item.pnd_terakhir,
      [headers.pend_saatini]: item.pend_saatini,
      [headers.sumber_penghasilan]: item.sumber === "1" ? "Ada" : "Tidak Ada",
      [headers.bansos]: item.bansos,
      [headers.bpjs_kt]: item.bpjs === 1 ? "Terdaftar" : "Tidak Terdaftar",
      [headers.status_perkawinan]: item.status_nikah,
      [headers.tanggal_perkawinan]: item.tgl_kawin || "-",
      [headers.akta_nikah]:
        item.pemilik_aktenikah === "1" ? "Ada" : "Tidak Ada",
      [headers.akta_cerai]:
        item.pemilik_aktecerai === "1" ? "Ada" : "Tidak Ada",
      [headers.kewarganegaraan]: item.kwn,
      [headers.nama_ayah]: item.ayah,
      [headers.nama_ibu]: item.ibu,
      [headers.golongan_darah]: item.darah,
      [headers.alamat]: item.alamat,
      [headers.rt_rw]: item.rt,
      [headers.desa]: item.desa,
      [headers.status_domisili]:
        item.status_domis === "1" ? "Tetap" : "Sementara",
      [headers.keterangan_domisili]: item.ket_domis || "-",
      [headers.mutasi]: item.mutasi === "1" ? "Ada" : "Tidak Ada",
      [headers.keterangan_mutasi]: item.ket_mutasi || "-",
      [headers.sebab_mutasi]: item.sebab_mutasi || "-",
      [headers.tanggal_mutasi]:
        item.tgl_mutasi === null ? "-" : item.tgl_mutasi,
      [headers.kondisi_kesehatan]: item.sehat,
      [headers.penyakit]: item.penyakit,
      [headers.disabilitas]: item.disabilitas,
      [headers.yatim_piatu]: item.yatim,
      [headers.bpjs_kes]:
        item.bpjs_kesehatan === "1" ? "Terdaftar" : "Tidak Terdaftar",
      [headers.status_rumah]: item.status_rumah,
      [headers.status_lahan]: item.status_lahan,
      [headers.layak_huni]: item.layak === 1 ? "Layak" : "Tidak Layak",
      [headers.penerangan]: item.penerangan,
      [headers.sumber_air]: item.sumber_air,
      [headers.jamban]: item.jamban === 1 ? "Ada" : "Tidak Ada",
    };
  });

  // Create a new work book
  const workBook: XLSX.WorkBook = XLSX.utils.book_new();

  // Create a work sheet from transformed data
  const workSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(transformedData, {
    header: Object.values(headers),
  });

  // Set header row to bold
  const range = XLSX.utils.decode_range(workSheet["!ref"]!);
  for (let C = range.s.c; C <= range.e.c; ++C) {
    const cell_address = XLSX.utils.encode_cell({ c: C, r: 0 });
    if (!workSheet[cell_address]) continue;
    workSheet[cell_address].s = {
      font: { bold: true },
      alignment: { horizontal: "center", vertical: "center" },
    };
  }

  // Set column width (optional)
  workSheet["!cols"] = Array(Object.keys(headers).length).fill({ wch: 20 });

  // Add the work sheet to the work book
  XLSX.utils.book_append_sheet(workBook, workSheet, "Sheet1");

  // Export the Excel file
  XLSX.writeFile(workBook, `${fileName}.xlsx`);
};
