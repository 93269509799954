export interface Ekspedisi {
  id_ekspedisi: number;
  tgl_surat: string;
  nomor: string;
  penerima: string;
  alamat: string;
  perihal: string;
  tgl: string;
  ket: string;
  paraf: string;
  file: string;
}

export const fetchEkspedisi = async (): Promise<Ekspedisi[]> => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/ekspedisi/${process.env.REACT_APP_TOKEN}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    }
  );
  const data = await response.json();

  return data;
};

export const filterData = (data: Ekspedisi[], search: string): Ekspedisi[] => {
  return data.filter((ekspedisi: Ekspedisi) => {
    const nomor = ekspedisi.nomor ? ekspedisi.nomor.toLowerCase() : "";
    const lowerCaseSearch = search.toLowerCase();

    return nomor.includes(lowerCaseSearch);
  });
};
// Fungsi untuk menghitung total halaman
export const getTotalPages = (
  filteredData: Ekspedisi[],
  showCount: number
): number => {
  return Math.ceil(filteredData.length / showCount);
};

// Fungsi untuk mendapatkan data berdasarkan halaman saat ini
export const getPaginatedData = (
  filteredData: Ekspedisi[],
  currentPage: number,
  showCount: number
): Ekspedisi[] => {
  return filteredData.slice(
    (currentPage - 1) * showCount,
    currentPage * showCount
  );
};

export const fetchEditEkspedisi = async (id: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/edit_ekspedisi/${id}/${process.env.REACT_APP_TOKEN}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    );
    console.log(response);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error("Error fetching form data:", error);
    throw error;
  }
};

export const validateFormData = (formData: any) => {
  const errors: any = {};

  // Validasi untuk setiap field
  if (!formData.nomor) errors.nomor = "Nomor Surat harus diisi!";
  if (!formData.tgl_surat) errors.tgl_surat = "Tanggal Surat harus diisi!";
  if (!formData.penerima) errors.penerima = "Penerima Surat harus diisi!";
  if (!formData.alamat) errors.alamat = "Alamat Penerima Surat diisi!";
  if (!formData.perihal) errors.perihal = "Perihal harus diisi!";
  if (!formData.tgl) errors.tgl = "Tanggal Penerimaan diisi!";
  if (!formData.ket) errors.ket = "Keterangan Surat diisi!";

  return errors;
};

export const submitFormData = async (formData: any, isEdit: boolean) => {
  const url = isEdit
    ? `${process.env.REACT_APP_API_URL}/update_ekspedisi`
    : `${process.env.REACT_APP_API_URL}/mekspedisi`;

  try {
    // Check if formData is already FormData, else convert it
    const body = formData instanceof FormData ? formData : new FormData();
    if (!(formData instanceof FormData)) {
      Object.entries(formData).forEach(([key, value]) => {
        body.append(key, value as string | Blob);
      });
    }

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: body,
    });

    const responseText = await response.text(); // Get response text for more information
    if (!response.ok) {
      console.error("Error response:", responseText);
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    console.log("Form data submitted successfully!");
  } catch (error) {
    console.error("Error submitting form data:", error);
    throw error;
  }
};
