import React, { useEffect, useState } from "react";
import {
  Artikel,
  fetchArtikel,
  filterData,
  getTotalPages,
  getPaginatedData,
} from "./Controller";
import { Link, Navigate, useLocation } from "react-router-dom";
import LayoutBackend from "../../../../components/layouts/backend/Main";
import TabelC from "../../../../components/layouts/backend/Tabel";
import { useStateContext } from "../../../../middleware";

const BeritaPage: React.FC = () => {
  const [data, setData] = useState<Artikel[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState<string>("");
  const [showCount, setShowCount] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const location = useLocation();
  const [message, setMessage] = useState(location.state?.message);
  const { token } = useStateContext();
  // Mengambil data dari API
  useEffect(() => {
    document.title = "Data Berita Desa";
    const loadData = async () => {
      try {
        const data = await fetchArtikel();
        setData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, []);

  // Mengatur nilai input pencarian dan mereset halaman ke pertama saat nilai pencarian berubah
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  // Mengatur jumlah data yang ditampilkan per halaman dan mereset halaman ke pertama saat jumlah data yang ditampilkan berubah
  const handleShowCountChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setShowCount(Number(e.target.value));
    setCurrentPage(1);
  };

  // Mengatur halaman berikutnya
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Mengatur halaman sebelumnya
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Memfilter data berdasarkan nilai input pencarian
  const filteredData = filterData(data, search);

  // Menghitung total halaman
  const totalPages = getTotalPages(filteredData, showCount);

  // Mendapatkan data yang akan ditampilkan berdasarkan halaman saat ini
  const paginatedData = getPaginatedData(filteredData, currentPage, showCount);
  const handleDelete = async (id: number) => {
    if (window.confirm("Apakah Anda yakin ingin menghapus data ini?")) {
      await deleteData(id);
    }
  };
  const deleteData = async (id: number) => {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/martikel/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      setData(data.filter((anggota) => anggota.id_artikel !== id));
      setMessage("Data Berhasil dihapus");
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };
  const truncateText = (text: any, wordLimit: any) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };
  if (!token) return <Navigate to="/login" />;
  return (
    <LayoutBackend>
      {message && (
        <div className="alert alert-success">
          {" "}
          <i className="fas fa-check"></i> {message}
        </div>
      )}
      <div className="col-sm-12">
        <div className="d-flex justify-content-between align-items-center">
          <h6 className="mb-0 text-uppercase">Data Berita Desa</h6>
          <div className="d-flex">
            <Link to="/kategori" className="btn btn-success me-2">
              Kategori
            </Link>
            <Link to="/tambah_berita" className="btn btn-primary">
              + Tambah
            </Link>
          </div>
        </div>
      </div>
      <hr />
      <TabelC
        value={showCount}
        onChange={handleShowCountChange}
        search={search}
        handleSearchChange={handleSearchChange}
      >
        <div className="table-responsive">
          {loading ? (
            <center>Loading....</center>
          ) : (
            <>
              <table
                id="example"
                className="table table-striped table-bordered"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Judul</th>
                    <th>Kategori</th>
                    <th>Status</th>
                    <th>Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedData.map((artikel, index) => (
                    <tr key={artikel.id_artikel}>
                      <td>{(currentPage - 1) * showCount + index + 1}</td>
                      <td> {truncateText(artikel.title, 5)}</td>
                      <td>{artikel.nama}</td>
                      <td>
                        {artikel.status === 1 ? (
                          <span className="badge bg-success">Publish</span>
                        ) : (
                          <span className="badge bg-danger">Draf</span>
                        )}
                      </td>
                      <td>
                        <Link
                          to={`/edit_artikel/${artikel.slug}`}
                          className="btn  btn-sm btn-primary m-1"
                        >
                          <i className="bi bi-pencil"></i>
                        </Link>
                        <Link
                          to={"#"}
                          className="btn btn-sm btn-danger"
                          onClick={() => handleDelete(artikel.id_artikel)}
                        >
                          <i className="bi bi-trash"></i>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="d-flex justify-content-between align-items-center">
                <div
                  className="dataTables_info"
                  id="example2_info"
                  role="status"
                  aria-live="polite"
                >
                  Showing {(currentPage - 1) * showCount + 1} to{" "}
                  {Math.min(currentPage * showCount, filteredData.length)} of{" "}
                  {filteredData.length} entries
                </div>
                <div id="example_paginate">
                  <ul className="pagination">
                    <li
                      className={`paginate_button page-item previous ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={handlePrevPage}
                        disabled={currentPage === 1}
                      >
                        Prev
                      </button>
                    </li>
                    {[...Array(totalPages)].map((_, pageIndex) => (
                      <li
                        key={pageIndex}
                        className={`paginate_button page-item ${
                          currentPage === pageIndex + 1 ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => setCurrentPage(pageIndex + 1)}
                        >
                          {pageIndex + 1}
                        </button>
                      </li>
                    ))}
                    <li
                      className={`paginate_button page-item next ${
                        currentPage === totalPages ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                      >
                        Next
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </>
          )}
        </div>
      </TabelC>
    </LayoutBackend>
  );
};

export default BeritaPage;
