import { useEffect, useState } from "react";
import {
  fetchEditSkKades,
  submitFormData,
  validateFormData,
} from "./Controller";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import LayoutBackend from "../../../../components/layouts/backend/Main";
import InputC from "../../../../components/layouts/backend/Input";
import FormC from "../../../../components/layouts/backend/Form";
import SelectC from "../../../../components/layouts/backend/Select";
import { useStateContext } from "../../../../middleware";

const AksiAparat = () => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useStateContext();
  const [formData, setFormData] = useState({
    nama: "",
    jk: "",
    tempat: "",
    tgl: "",
    sk: "",
    hp: "",
    tgl_berhenti: "",
    user_id: 1,
  });

  const isEdit = window.location.pathname.includes("tambah_kk")
    ? false
    : Boolean(id);
  useEffect(() => {
    document.title = isEdit ? "Edit Aparat Desa" : "Tambah Aparat Desa";
    if (id && window.location.pathname.includes("edit_aparat")) {
      fetchEditSkKades(id)
        .then((data) => setFormData(data))
        .catch((error) => console.error("Error:", error));
    }
  }, [id, isEdit]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target as HTMLInputElement;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const validationErrors = validateFormData(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setLoading(false);
      return;
    }
    try {
      await submitFormData(formData, isEdit);
      navigate("/aparat", {
        state: {
          message: isEdit
            ? "Data berhasil diperbarui!"
            : "Data berhasil ditambahkan!",
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  if (!token) return <Navigate to="/login" />;
  return (
    <>
      <LayoutBackend>
        <h5 className="card-title">
          {" "}
          {isEdit ? "Edit Aparat Desa" : "Tambah Aparat Desa"}{" "}
        </h5>
        <hr />
        <FormC onSubmit={handleSubmit}>
          <div className="row">
            <InputC
              type="text"
              col="col-md-6 mb-3"
              label="Nama Lengkap"
              name="nama"
              value={formData.nama}
              onChange={handleChange}
              placeholder="Nama Lengkap..."
              error={errors?.nama}
            />
            <SelectC
              col="col-6"
              label="Jenis Kelamin"
              name="jk"
              onChange={handleChange}
              value={formData.jk}
              error={errors?.jk}
            >
              <option value="1">Laki - Laki</option>
              <option value="2">Perempuan</option>
            </SelectC>
            <InputC
              type="text"
              col="col-md-6 mb-3"
              label="Tempat Lahir"
              name="tempat"
              value={formData.tempat}
              onChange={handleChange}
              placeholder="Tempat Lahir..."
              error={errors?.tempat}
            />
            <InputC
              type="date"
              col="col-md-6 mb-3"
              label="Tanggal Lahir"
              name="tgl"
              value={formData.tgl}
              onChange={handleChange}
              error={errors?.tgl}
            />
            <InputC
              type="text"
              col="col-md-6 mb-3"
              label="Nomor SK Pengangkatan"
              name="sk"
              value={formData.sk}
              onChange={handleChange}
              placeholder="Nomor SK Pengangkatan..."
              error={errors?.sk}
            />
            <InputC
              type="date"
              col="col-md-6 mb-3"
              label="Tanggal Pemberhentian"
              name="tgl_berhenti"
              value={formData.tgl_berhenti}
              onChange={handleChange}
              placeholder="Tanggal Pemberhentian..."
              error={errors?.tgl_berhenti}
            />
            <InputC
              type="text"
              col="col-md-6 mb-3"
              label="Nomor HP"
              name="hp"
              value={formData.hp}
              onChange={handleChange}
              placeholder="Nomor HP..."
              error={errors?.hp}
            />
          </div>
          <button
            type="submit"
            className="btn btn-primary btn-sm me-2"
            disabled={loading}
          >
            {loading ? "Loading..." : "Simpan"}
          </button>
          <Link to="/aparat" className="btn btn-danger btn-sm">
            Kembali
          </Link>
        </FormC>
      </LayoutBackend>
    </>
  );
};

export default AksiAparat;
