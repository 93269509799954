import { useEffect } from "react";
import FooterC from "./FooterC";
import HeaderC from "./Header";
import MenuC from "./Menu";

const LayoutBackend = (props: any) => {
  const { children } = props;

  useEffect(() => {
    const scripts = [
      "https://code.jquery.com/jquery-3.7.1.js",
      "/assets/plugins/simplebar/js/simplebar.min.js",
      "/assets/plugins/metismenu/js/metisMenu.min.js",
      "/assets/plugins/perfect-scrollbar/js/perfect-scrollbar.js",
      "/assets/js/pace.min.js",
      "/assets/js/app.js",
      "/assets/js/index.js",
      "/assets/plugins/select2/js/select2.min.js",
      "/assets/js/form-select2.js",
    ];

    scripts.forEach((src) => {
      const script = document.createElement("script");
      script.src = src;
      script.async = true;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    });
  }, []);

  return (
    <>
      <title>Onedash - Bootstrap 5 Admin Template</title>
      <div className="wrapper">
        <HeaderC />
        <MenuC />
        <main className="page-content">{children}</main>
        <div className="overlay nav-toggle-icon" />
        <FooterC />
      </div>
    </>
  );
};

export default LayoutBackend;
