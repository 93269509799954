import { useEffect, useState } from "react";
import LayoutBackend from "../../../components/layouts/backend/Main";
import { fetchPenduduk, filterData, Penduduk } from "./Controller";
import { Link, Navigate, useLocation } from "react-router-dom";
import { formatTanggalIndonesia } from "../../Helper";
import { useStateContext } from "../../../middleware";

const PendudukPage = () => {
  const [data, setData] = useState<Penduduk[]>([]);
  const [filteredData, setFilteredData] = useState<Penduduk[]>([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState<string>("");
  const [showCount, setShowCount] = useState<number>(100);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const location = useLocation();
  const [message, setMessage] = useState(location.state?.message);
  const [formData, setFormData] = useState<any>({
    file: "",
  });
  const { token } = useStateContext();
  const updatePageData = (paginationData: any) => {
    setData(paginationData.data);
    setCurrentPage(paginationData.current_page);
    setTotalPages(paginationData.last_page);
    setLoading(false);
  };

  useEffect(() => {
    document.title = "Data Artikel";
    const loadData = async () => {
      try {
        const paginationData = await fetchPenduduk(currentPage, showCount);
        updatePageData(paginationData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    loadData();
  }, [currentPage, showCount]);

  useEffect(() => {
    const result = filterData(data, search);
    setFilteredData(result);
  }, [data, search]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const handleShowCountChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setShowCount(Number(e.target.value));
    setCurrentPage(1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleDelete = async (id: number) => {
    if (window.confirm("Apakah Anda yakin ingin menghapus data ini?")) {
      await deleteData(id);
    }
  };
  const deleteData = async (id: number) => {
    console.log(process.env.REACT_APP_API_URL);
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/penduduk/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      setData(data.filter((data) => data.id_pend !== id));
      setMessage("Data Berhasil dihapus");
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { files } = e.target as HTMLInputElement;

    if (files && files.length > 0) {
      setFormData((prevData: any) => ({
        ...prevData,
        file: files[0], // Store the first file
      }));
    }
  };
  const handleUpload = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    const formDataToSend = new FormData();
    formDataToSend.append("file", formData.file); // 'file' should match the backend's expected key

    try {
      await fetch(`${process.env.REACT_APP_API_URL}/upload_penduduk`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Include only Authorization header
        },
        body: formDataToSend,
      });
      setMessage("Data Berhasil diUpload");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  if (!token) {
    return <Navigate to="/login" />;
  }
  return (
    <LayoutBackend>
      <>
        {message && (
          <div className="alert alert-success">
            {" "}
            <i className="fas fa-check"></i> {message}
          </div>
        )}
        <div className="col-sm-12">
          <div className="d-flex justify-content-between align-items-center">
            <h6 className="mb-0 text-uppercase">Data Penduduk</h6>
            <div className="d-flex">
              <Link
                to="/tambah_penduduk"
                className="btn btn-success me-2"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                <i className="bi bi-upload"></i> Upload
              </Link>
              <Link to="/tambah_penduduk" className="btn btn-primary">
                +Tambah
              </Link>
            </div>
          </div>
        </div>
        <hr />
        <div className="card">
          <div className="card-body">
            <div className="table-responsive">
              <div className="col-sm-12 mb-3">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="dataTables_length">
                    <label>
                      Show{" "}
                      <select
                        name="example_length"
                        aria-controls="example"
                        className="form-select form-select-sm"
                        value={showCount}
                        onChange={handleShowCountChange}
                      >
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </label>
                  </div>
                  <div className="dataTables_filter">
                    <label className="w-100">
                      Search:
                      <input
                        type="search"
                        className="form-control form-control-sm"
                        placeholder=""
                        aria-controls="example"
                        value={search}
                        onChange={handleSearchChange}
                      />
                    </label>
                  </div>
                </div>
              </div>
              <table
                id="example"
                className="table table-striped table-bordered"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th>No</th>
                    <th>NIK</th>
                    <th>Nama</th>
                    <th>Jenis Kelamin</th>
                    <th>TTL</th>
                    <th>Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan={6} className="text-center">
                        Loading...
                      </td>
                    </tr>
                  ) : filteredData.length ? (
                    filteredData.map((penduduk, index) => (
                      <tr key={penduduk.id_pend}>
                        <td> {(currentPage - 1) * showCount + index + 1}</td>
                        <td>{penduduk.nik}</td>
                        <td>{penduduk.nama}</td>
                        <td>
                          {penduduk.jenis === 1 ? "Laki-laki" : "Perempuan"}
                        </td>
                        <td>
                          {penduduk.tempat},
                          {formatTanggalIndonesia(penduduk.tgl)}
                        </td>
                        <td>
                          <Link
                            to={`/edit_penduduk/${penduduk.id_pend}`}
                            className="btn  btn-sm btn-primary"
                          >
                            <i className="bi bi-pencil"></i>
                          </Link>
                          <Link
                            to={`/lihat_penduduk/${penduduk.id_pend}`}
                            className="btn  btn-sm btn-warning m-1"
                          >
                            <i className="bi bi-eye"></i>
                          </Link>
                          {penduduk.shdrt === "1" ? (
                            <>
                              <Link
                                to={`/tambah_kk/${penduduk.no_urut}`}
                                className="btn  btn-sm btn-success m-1"
                                title="Tambah KK"
                              >
                                <i className="bi bi-person-circle"></i>
                              </Link>
                            </>
                          ) : null}
                          <Link
                            to={"#"}
                            className="btn btn-sm btn-danger"
                            onClick={() => handleDelete(penduduk.id_pend)}
                          >
                            <i className="bi bi-trash"></i>
                          </Link>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-center">
                        Tidak ada data
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="d-flex justify-content-between align-items-center">
                <div
                  className="dataTables_info"
                  id="example2_info"
                  role="status"
                  aria-live="polite"
                >
                  Showing {(currentPage - 1) * showCount + 1} to{" "}
                  {Math.min(currentPage * showCount, filteredData.length)} of{" "}
                  {filteredData.length} entries
                </div>
                <div id="example_paginate">
                  <ul className="pagination">
                    <li
                      className={`paginate_button page-item previous ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={handlePrevPage}
                        disabled={currentPage === 1}
                      >
                        Prev
                      </button>
                    </li>
                    {[...Array(totalPages)].map((_, pageIndex) => (
                      <li
                        key={pageIndex}
                        className={`paginate_button page-item ${
                          currentPage === pageIndex + 1 ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => setCurrentPage(pageIndex + 1)}
                        >
                          {pageIndex + 1}
                        </button>
                      </li>
                    ))}
                    <li
                      className={`paginate_button page-item next ${
                        currentPage === totalPages ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                      >
                        Next
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <>
          <div
            className="modal fade"
            id="exampleModal"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Upload Data Penduduk
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                {message && (
                  <div className="alert alert-success">
                    {" "}
                    <i className="fas fa-check"></i> {message}
                  </div>
                )}
                <div className="modal-body">
                  <form onSubmit={handleUpload}>
                    <div className="row mb-3">
                      <label
                        htmlFor="inputEnterYourName"
                        className="col-sm-2 col-form-label"
                      >
                        Template
                      </label>
                      <div className="col-sm-3">
                        <Link
                          to={`${process.env.REACT_APP_API_BASE}/upload_penduduk.xlsx`}
                          className="btn btn-success"
                        >
                          Download
                        </Link>
                      </div>
                    </div>

                    <div className="input-group mb-3">
                      <button
                        className="btn btn-outline-secondary"
                        type="submit"
                        id="inputGroupFileAddon03"
                      >
                        {loading ? "Loading..." : "Upload"}
                      </button>
                      <input
                        type="file"
                        className="form-control"
                        onChange={handleChange}
                        name="file"
                        id="inputGroupFile03"
                        aria-describedby="inputGroupFileAddon03"
                        aria-label="Upload"
                      />
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      </>
    </LayoutBackend>
  );
};

export default PendudukPage;
