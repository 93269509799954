import { useEffect, useState } from "react";
import { fetchLembaga, Lembaga } from "./Controller";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import LayoutBackend from "../../../../components/layouts/backend/Main";
import InputC from "../../../../components/layouts/backend/Input";
import FormC from "../../../../components/layouts/backend/Form";
import SelectC from "../../../../components/layouts/backend/Select";
import {
  fetchEditLkd,
  submitFormData,
  validateFormData,
} from "./LkdController";
import { useStateContext } from "../../../../middleware";

const AksiLkdPage = () => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const navigate = useNavigate();
  const { id } = useParams();
  const [lembaga, setLembaga] = useState<Lembaga[]>([]);
  const { token } = useStateContext();
  const [formData, setFormData] = useState({
    lembaga_id: "",
    alamat: "",
    pengurus: "",
    no_sk: "",
    user_id: 1,
  });

  const isEdit = window.location.pathname.includes("tambah_lkd")
    ? false
    : Boolean(id);
  useEffect(() => {
    document.title = isEdit
      ? "Edit Lembaga Kemasyarakatan Desa"
      : "Tambah Lembaga Kemasyarakatan Desa";
    if (id && window.location.pathname.includes("edit_lkd")) {
      fetchEditLkd(id)
        .then((data) => setFormData(data))
        .catch((error) => console.error("Error:", error));
    }
    fetchLembaga().then((data) => setLembaga(data));
  }, [id, isEdit]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target as HTMLInputElement;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const validationErrors = validateFormData(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setLoading(false);
      return;
    }
    try {
      await submitFormData(formData, isEdit);
      navigate("/lkd", {
        state: {
          message: isEdit
            ? "Data berhasil diperbarui!"
            : "Data berhasil ditambahkan!",
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Navigate to="/login" />;
  return (
    <>
      <LayoutBackend>
        <h5 className="card-title">
          {" "}
          {isEdit
            ? "Edit Lembaga Kemasyarakatan Desa"
            : "Tambah Lembaga Kemasyarakatan Desa"}{" "}
        </h5>
        <hr />
        <FormC onSubmit={handleSubmit}>
          <div className="row">
            <SelectC
              col="col-6"
              label="Lembaga"
              name="lembaga_id"
              onChange={handleChange}
              value={formData.lembaga_id}
              error={errors?.lembaga_id}
            >
              {lembaga.map((item) => (
                <option key={item.id_lembaga} value={item.id_lembaga}>
                  {item.nama}
                </option>
              ))}
            </SelectC>
            <InputC
              type="text"
              col="col-md-6 mb-3"
              label="Nama Pengurus"
              name="pengurus"
              value={formData.pengurus}
              onChange={handleChange}
              placeholder="Nama Pengurus..."
              error={errors?.pengurus}
            />
            <InputC
              type="text"
              col="col-md-6 mb-3"
              label="Alamat Pengurus"
              name="alamat"
              value={formData.alamat}
              onChange={handleChange}
              placeholder="Alamat Pengurus..."
              error={errors?.alamat}
            />

            <InputC
              type="text"
              col="col-md-6 mb-3"
              label="Nomor SK Pengurus"
              name="no_sk"
              value={formData.no_sk}
              onChange={handleChange}
              placeholder="Nomor SK Pengurus..."
              error={errors?.no_sk}
            />
          </div>
          <button
            type="submit"
            className="btn btn-primary btn-sm me-2"
            disabled={loading}
          >
            {loading ? "Loading..." : "Simpan"}
          </button>
          <Link to="/aparat" className="btn btn-danger btn-sm">
            Kembali
          </Link>
        </FormC>
      </LayoutBackend>
    </>
  );
};

export default AksiLkdPage;
