import LinkC from "./Link";

/* eslint-disable jsx-a11y/anchor-is-valid */
const MenuC = () => {
  return (
    <>
      <aside className="sidebar-wrapper" data-simplebar="true">
        <div className="sidebar-header">
          <div>
            <img
              src="/assets/images/logo-icon.png"
              className="logo-icon"
              alt="logo icon"
            />
          </div>
          <div>
            <h4 className="logo-text">DATAKU</h4>
          </div>
          <div className="toggle-icon ms-auto">
            {" "}
            <i className="bi bi-list" />
          </div>
        </div>
        {/*navigation*/}
        <ul className="metismenu" id="menu">
          <LinkC href="/dashboard" icon="bi bi-house-fill">
            Dashboard
          </LinkC>
          <li>
            <a href="#" className="has-arrow">
              <div className="parent-icon">
                <i className="bi bi-grid-fill" />
              </div>
              <div className="menu-title">Master Data</div>
            </a>
            <ul>
              <LinkC href="/penduduk" icon="bi bi-circle">
                Penduduk
              </LinkC>
            </ul>
          </li>
          <li className="menu-label">Administrasi</li>
          <li>
            <a href="#" className="has-arrow">
              <div className="parent-icon">
                <i className="bi bi-person-fill" />
              </div>
              <div className="menu-title">Penduduk</div>
            </a>
            <ul>
              <LinkC href="/rekapitulasi" icon="bi bi-circle">
                Rekapitulasi
              </LinkC>
              <LinkC href="/mutasi" icon="bi bi-circle">
                Mutasi
              </LinkC>
              <LinkC href="/domisili" icon="bi bi-circle">
                Domisili
              </LinkC>
            </ul>
          </li>
          <li>
            <a href="#" className="has-arrow">
              <div className="parent-icon">
                <i className="bi bi-folder-fill" />
              </div>
              <div className="menu-title">Umum</div>
            </a>
            <ul>
              <LinkC href="/perdes" icon="bi bi-circle">
                Perdes
              </LinkC>
              <LinkC href="/skkades" icon="bi bi-circle">
                SK Kades
              </LinkC>
              <LinkC href="/perkades" icon="bi bi-circle">
                Perkades
              </LinkC>
              <LinkC href="/inventaris" icon="bi bi-circle">
                Inventaris
              </LinkC>
              <LinkC href="/kekayaan" icon="bi bi-circle">
                Kekayaan Desa
              </LinkC>
              <LinkC href="/aparat" icon="bi bi-circle">
                Aparat Desa
              </LinkC>
              <LinkC href="/ekspedisi" icon="bi bi-circle">
                Buku Ekspedisi
              </LinkC>
              <LinkC href="/berita" icon="bi bi-circle">
                Berita
              </LinkC>
            </ul>
          </li>
          <li>
            <a href="#" className="has-arrow">
              <div className="parent-icon">
                <i className="lni lni-apartment" />
              </div>
              <div className="menu-title">Pembangunan</div>
            </a>
            <ul>
              <LinkC href="/rencana" icon="bi bi-circle">
                RKP
              </LinkC>
              <LinkC href="/kegiatan" icon="bi bi-circle">
                Kegiatan Pembangunan
              </LinkC>
              <LinkC href="/ihp" icon="bi bi-circle">
                IHP
              </LinkC>
            </ul>
          </li>
          <LinkC href="/lkd" icon="lni lni-microsoft">
            Lembaga Masyarakat
          </LinkC>
          <LinkC href="/notifikasi" icon="lni lni-bullhorn">
            Notifikasi
          </LinkC>
        </ul>
        {/*end navigation*/}
      </aside>
    </>
  );
};

export default MenuC;
