import { useEffect, useState } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import LayoutBackend from "../../../../components/layouts/backend/Main";
import {
  fetchLembaga,
  filterData,
  getPaginatedData,
  getTotalPages,
  Lembaga,
} from "./Controller";
import TabelC from "../../../../components/layouts/backend/Tabel";
import { useStateContext } from "../../../../middleware";

const LembagaPage = () => {
  const [data, setData] = useState<Lembaga[]>([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState<string>("");
  const [showCount, setShowCount] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const location = useLocation();
  const [message, setMessage] = useState(location.state?.message);
  const { token } = useStateContext();
  useEffect(() => {
    document.title = "Data Lembaga Kemasyarakatan Desa";
    const loadData = async () => {
      try {
        setLoading(true);
        const paginationData = await fetchLembaga();
        setData(paginationData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, []);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const handleShowCountChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setShowCount(Number(e.target.value));
    setCurrentPage(1);
  };

  const filteredData = filterData(data, search);
  const totalPages = getTotalPages(filteredData, showCount);
  const paginatedData = getPaginatedData(filteredData, currentPage, showCount);
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleDelete = async (id: number) => {
    if (window.confirm("Apakah Anda yakin ingin menghapus data ini?")) {
      await deleteData(id);
    }
  };
  const deleteData = async (id: number) => {
    console.log(process.env.REACT_APP_API_URL);
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/mlembaga/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      setData(data.filter((data) => data.id_lembaga !== id));
      setMessage("Data Berhasil dihapus");
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };
  if (!token) return <Navigate to="/login" />;
  return (
    <LayoutBackend>
      <>
        {message && (
          <div className="alert alert-success">
            {" "}
            <i className="fas fa-check"></i> {message}
          </div>
        )}
        <div className="col-sm-12">
          <div className="d-flex justify-content-between align-items-center">
            <h6 className="mb-0 text-uppercase">Data Lembaga</h6>
            <div className="d-flex">
              <Link to="/lkd" className="btn btn-danger me-2">
                Kembali
              </Link>
              <Link to="/tambah_lembaga" className="btn btn-primary">
                Tambah
              </Link>
            </div>
          </div>
        </div>
        <hr />
        <TabelC
          value={showCount}
          onChange={handleShowCountChange}
          search={search}
          handleSearchChange={handleSearchChange}
        >
          <table
            id="example"
            className="table table-striped table-bordered"
            style={{ width: "100%" }}
          >
            <thead>
              <tr>
                <th>No</th>
                <th>Lembaga</th>
                <th>Aksi</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={7} className="text-center">
                    Loading...
                  </td>
                </tr>
              ) : paginatedData.length ? (
                paginatedData.map((lembaga, index) => (
                  <tr key={lembaga.id_lembaga}>
                    <td> {(currentPage - 1) * showCount + index + 1}</td>
                    <td>{lembaga.nama}</td>

                    <td>
                      <Link
                        to={`/edit_lembaga/${lembaga.id_lembaga}`}
                        className="btn  btn-sm btn-primary m-1"
                      >
                        <i className="bi bi-pencil"></i>
                      </Link>
                      <Link
                        to={"#"}
                        className="btn btn-sm btn-danger"
                        onClick={() => handleDelete(lembaga.id_lembaga)}
                      >
                        <i className="bi bi-trash"></i>
                      </Link>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7} className="text-center">
                    Tidak ada data
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="d-flex justify-content-between align-items-center">
            <div
              className="dataTables_info"
              id="example2_info"
              role="status"
              aria-live="polite"
            >
              Showing {(currentPage - 1) * showCount + 1} to{" "}
              {Math.min(currentPage * showCount, filteredData.length)} of{" "}
              {filteredData.length} entries
            </div>
            <div id="example_paginate">
              <ul className="pagination">
                <li
                  className={`paginate_button page-item previous ${
                    currentPage === 1 ? "disabled" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  >
                    Prev
                  </button>
                </li>
                {[...Array(totalPages)].map((_, pageIndex) => (
                  <li
                    key={pageIndex}
                    className={`paginate_button page-item ${
                      currentPage === pageIndex + 1 ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => setCurrentPage(pageIndex + 1)}
                    >
                      {pageIndex + 1}
                    </button>
                  </li>
                ))}
                <li
                  className={`paginate_button page-item next ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </TabelC>
      </>
    </LayoutBackend>
  );
};

export default LembagaPage;
