import { useEffect, useState } from "react";
import {
  fetchEditPerdes,
  submitFormData,
  validateFormData,
} from "./Controller";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import LayoutBackend from "../../../../components/layouts/backend/Main";
import InputC from "../../../../components/layouts/backend/Input";
import TextareaC from "../../../../components/layouts/backend/Textarea";
import { useStateContext } from "../../../../middleware";

const AksiPerdes = () => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useStateContext();
  const [formData, setFormData] = useState({
    nomor: "",
    judul: "",
    tentang: "",
    tgl: "",
    user_id: 1,
    status: 1,
  });

  const isEdit = window.location.pathname.includes("tambah_kk")
    ? false
    : Boolean(id);
  console.log(isEdit);
  useEffect(() => {
    document.title = isEdit ? "Edit Peraturan Desa" : "Tambah Peraturan Desa";
    if (id && window.location.pathname.includes("edit_perdes")) {
      fetchEditPerdes(id)
        .then((data) => setFormData(data))
        .catch((error) => console.error("Error:", error));
    }
  }, [id, isEdit]);
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target as HTMLInputElement;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const validationErrors = validateFormData(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setLoading(false);
      return;
    }
    try {
      await submitFormData(formData, isEdit);
      navigate("/perdes", {
        state: {
          message: isEdit
            ? "Data berhasil diperbarui!"
            : "Data berhasil ditambahkan!",
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  if (!token) return <Navigate to="/login" />;
  return (
    <>
      <LayoutBackend>
        <h5 className="card-title">
          {" "}
          {isEdit ? "Edit Peraturan Desa" : "Tambah Peraturan Desa"}{" "}
        </h5>
        <hr />
        <div className="card">
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <InputC
                type="text"
                col="col-md-12 mb-3"
                label="Nomor Peraturan"
                name="nomor"
                value={formData.nomor}
                onChange={handleChange}
                placeholder="Nomor Peraturan..."
                error={errors?.nomor}
              />
              <InputC
                type="text"
                col="col-md-12 mb-3"
                label="Nama Peraturan"
                name="judul"
                value={formData.judul}
                onChange={handleChange}
                placeholder="Nama Peraturan..."
                error={errors?.judul}
              />
              <InputC
                type="date"
                col="col-md-12 mb-3"
                label="Tanggal Penerapan"
                name="tgl"
                value={formData.tgl}
                onChange={handleChange}
                error={errors?.tgl}
              />
              <TextareaC
                col="col-md-12 mb-3"
                label="Tentang"
                name="tentang"
                value={formData.tentang}
                onChange={handleChange}
                placeholder="Tentang..."
                error={errors?.tentang}
              />
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                {loading ? "Loading..." : "Simpan"}
              </button>
            </form>
          </div>
        </div>
      </LayoutBackend>
    </>
  );
};

export default AksiPerdes;
