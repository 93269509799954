import { Navigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchEditKekayaan, Inventaris } from "./Controller";
import { Link } from "react-router-dom";
import LayoutBackend from "../../../../components/layouts/backend/Main";
import { useStateContext } from "../../../../middleware";

const DetailKekayaanPage = () => {
  const { id } = useParams();
  const [data, setData] = useState<Inventaris>();
  const [loading, setLoading] = useState(true);
  const { token } = useStateContext();
  // const { token } = useStateContext();
  // Mengambil data dari API
  useEffect(() => {
    document.title = "Detail Data Anggota";
    const loadData = async () => {
      try {
        const data = await fetchEditKekayaan(
          window.location.pathname.split("/")[2]
        );
        setData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [data]);
  if (!token) return <Navigate to="/login" />;
  return (
    <>
      <LayoutBackend>
        <div className="row">
          <div className="col-12 col-lg-8">
            <div className="card shadow-sm border-0">
              <div className="card-body">
                <h5 className="mb-0">Informasi Inventaris</h5>
                <hr />
                <div className="card shadow-none border">
                  <div className="card-body">
                    {loading ? (
                      <div>Loading...</div>
                    ) : (
                      <>
                        <table className="table table-hover table-sm table-striped table-bordered">
                          <tbody>
                            <tr>
                              <td>Jenis Kekayaan</td> <td>:</td>{" "}
                              <td> {data?.jenis}</td>
                            </tr>
                            <tr>
                              <td>Deskripsi Kekayaan</td> <td>:</td>
                              <td>{data?.nama}</td>
                            </tr>
                            <tr>
                              <td>Luas </td> <td>:</td> <td> {data?.luas}</td>
                            </tr>
                            <tr>
                              <td>Lokasi </td> <td>:</td>{" "}
                              <td> {data?.lokasi}</td>
                            </tr>
                            <tr>
                              <td>Tahun Perolehan</td> <td>:</td>{" "}
                              <td>{data?.tahun}</td>
                            </tr>
                            <tr>
                              <td>Nilai Kekayaan</td> <td>:</td>
                              <td>{data?.harga}</td>
                            </tr>
                            <tr>
                              <td>Sumber Dana</td> <td>:</td>
                              <td>{data?.sumber}</td>
                            </tr>
                            <tr>
                              <td>Pemanfaatan</td> <td>:</td>
                              <td>{data?.lokasi}</td>
                            </tr>
                            <tr>
                              <td>Status Kepemilikan </td> <td>:</td>
                              <td>{data?.status}</td>
                            </tr>
                            <tr>
                              <td>Kondisi Barang</td> <td>:</td>
                              <td>{data?.kondisi}</td>
                            </tr>
                          </tbody>
                        </table>
                      </>
                    )}
                  </div>
                </div>
                <Link to="/kekayaan" className="btn btn-sm btn-outline-danger">
                  Kembali
                </Link>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="card shadow-sm border-0 overflow-hidden">
              <div className="card-body">
                <div className="profile-avatar text-center">
                  <h5 className="text-uppercase">Bukti SK Kekayaan</h5>
                  {data?.sk !== null ? (
                    <>
                      <Link
                        to={`${process.env.REACT_APP_API_IMAGE}/${data?.sk}`}
                        target="_blank"
                      >
                        <img
                          src={`${process.env.REACT_APP_API_IMAGE}/${data?.sk}`}
                          width={120}
                          height={120}
                          alt=""
                        />
                      </Link>
                    </>
                  ) : (
                    <>
                      <img src="/default.png" width={120} height={120} alt="" />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutBackend>
    </>
  );
};

export default DetailKekayaanPage;
