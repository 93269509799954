export interface Penduduk {
  id_pend: number;
  nama: string; // Updated from number to string
  nik: string; // Updated from number to string
  ke_akte: number; // New field
  jenis: number;
  no_urut: number;
  tempat: string;
  tgl: string;
  darah: string;
  alamat: string;
  rt: string;
  desa: string;
  ke_ktp: string; // New field
  agama: string;
  pend_saatini: string; // New field
  kwn: string;
  pekerjaan: string;
  ayah: string;
  ibu: string;
  pnd_terakhir: string;
  shdrt: string;
  kondisi_peker: number; // New field
  sumber: string; // New field
  bansos: string; // New field
  bpjs: number; // New field
  status_nikah: number; // Renamed from status
  tgl_kawin: string; // New field
  pemilik_aktenikah: string | null; // New field
  pemilik_aktecerai: string | null; // New field
  status_domis: string; // New field
  ket_domis: string; // New field
  mutasi: string; // New field
  tujuan_mutasi: string; // New field
  ket_mutasi: string; // New field
  sebab_mutasi: string; // New field
  tgl_mutasi: string; // New field
  sehat: string; // New field
  penyakit: string; // New field
  disabilitas: string; // New field
  yatim: string; // New field
  status_rumah: number; // New field
  status_lahan: number; // New field
  layak: number; // New field
  penerangan: string; // New field
  sumber_air: string; // New field
  jamban: number; // New field
  tanggal: string; // Maintained
  bpjs_kesehatan: string; // Maintained
  umur: number;
}

export const fetchPenduduk = async (page: number, showCount: number) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/penduduk?page=${page}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    }
  );
  const data = await response.json();
  return data;
};
export const fetchMutasi = async (page: number, showCount: number) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/mutasi/1/${process.env.REACT_APP_TOKEN}?page=${page}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    }
  );
  const data = await response.json();
  return data;
};
export const fetchDomisili = async (page: number, showCount: number) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/mutasi/2/${process.env.REACT_APP_TOKEN}?page=${page}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    }
  );
  const data = await response.json();
  return data;
};
export const fetchUmur = async (): Promise<Penduduk[]> => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/umur/${process.env.REACT_APP_TOKEN}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    }
  );
  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
  const data: Penduduk[] = await response.json();
  return data;
};
export const filterData = (data: Penduduk[], search: string): Penduduk[] => {
  return data.filter((penduduk: Penduduk) => {
    const nama = penduduk.nama ? penduduk.nama.toLowerCase() : "";
    const nik = penduduk.nik ? penduduk.nik.toString() : "";
    const no_urut = penduduk.no_urut ? penduduk.no_urut.toString() : "";
    const lowerCaseSearch = search.toLowerCase();

    return (
      nama.includes(lowerCaseSearch) ||
      nik.includes(lowerCaseSearch) ||
      no_urut.includes(lowerCaseSearch)
    );
  });
};
// Fungsi untuk menghitung total halaman
export const getTotalPages = (
  filteredData: Penduduk[],
  showCount: number
): number => {
  return Math.ceil(filteredData.length / showCount);
};

// Fungsi untuk mendapatkan data berdasarkan halaman saat ini
export const getPaginatedData = (
  filteredData: Penduduk[],
  currentPage: number,
  showCount: number
): Penduduk[] => {
  return filteredData.slice(
    (currentPage - 1) * showCount,
    currentPage * showCount
  );
};

export const fetchDetailPenduduk = async (id: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/lihat_penduduk`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify({ id: id, token: process.env.REACT_APP_TOKEN }),
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching form data:", error);
    throw error;
  }
};
export const fetchKeluarga = async (id: string) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/keluarga`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        no_urut: id,
        token: process.env.REACT_APP_TOKEN,
      }),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data: Penduduk[] = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching form data:", error);
    throw error;
  }
};

export const fetchEditPenduduk = async (id: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/edit_penduduk/${id}/${process.env.REACT_APP_TOKEN}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error("Error fetching form data:", error);
    throw error;
  }
};

export const calculateAge = (birthdate: string): number => {
  const birthDate = new Date(birthdate);
  const today = new Date();

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  // Check if the birthday has occurred this year; if not, subtract one year from age
  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
};

export const validateFormData = (formData: any) => {
  const errors: any = {};

  // Validasi untuk setiap field
  if (!formData.nik) errors.nik = "NIK harus diisi!";
  if (!formData.nama) errors.nama = "Nama harus diisi!";
  if (!formData.tempat) errors.tempat = "Tempat lahir harus diisi!";
  if (!formData.tgl) errors.tgl = "Tanggal lahir harus diisi!";
  if (!formData.jenis) errors.jenis = "Jenis kelamin harus diisi!";
  if (!formData.status_nikah) errors.status_nikah = "Status nikah harus diisi!";
  if (!formData.alamat) errors.alamat = "Alamat harus diisi!";
  if (!formData.rt) errors.rt = "RT harus diisi!";
  if (!formData.desa) errors.desa = "Desa harus diisi!";
  if (!formData.agama) errors.agama = "Agama harus diisi!";
  if (!formData.pend_saatini)
    errors.pend_saatini = "Pendidikan saat ini harus diisi!";
  if (!formData.kwn) errors.kwn = "Kewarganegaraan harus diisi!";
  if (!formData.pekerjaan) errors.pekerjaan = "Pekerjaan harus diisi!";
  if (!formData.ayah) errors.ayah = "Nama ayah harus diisi!";
  if (!formData.ibu) errors.ibu = "Nama ibu harus diisi!";
  if (!formData.pnd_terakhir)
    errors.pnd_terakhir = "Pendidikan terakhir harus diisi!";
  if (!formData.shdrt) errors.shdrt = "SHDRT harus diisi!";
  if (!formData.kondisi_peker)
    errors.kondisi_peker = "Kondisi pekerjaan harus diisi!";
  if (!formData.sumber) errors.sumber = "Sumber harus diisi!";
  if (!formData.bansos) errors.bansos = "Bansos harus diisi!";
  if (!formData.bpjs) errors.bpjs = "BPJS harus diisi!";
  if (!formData.pemilik_aktenikah)
    errors.pemilik_aktenikah = "Pemilik akta nikah harus diisi!";
  if (!formData.pemilik_aktecerai)
    errors.pemilik_aktecerai = "Pemilik akta cerai harus diisi!";

  if (!formData.sehat) errors.sehat = "Status kesehatan harus diisi!";
  if (!formData.penyakit) errors.penyakit = "Penyakit harus diisi!";
  if (!formData.disabilitas) errors.disabilitas = "Disabilitas harus diisi!";
  if (!formData.yatim) errors.yatim = "Status yatim harus diisi!";
  if (!formData.status_rumah) errors.status_rumah = "Status rumah harus diisi!";
  if (!formData.status_lahan) errors.status_lahan = "Status lahan harus diisi!";
  if (!formData.layak) errors.layak = "Kelayakan harus diisi!";
  if (!formData.penerangan) errors.penerangan = "Penerangan harus diisi!";
  if (!formData.sumber_air) errors.sumber_air = "Sumber air harus diisi!";
  if (!formData.jamban) errors.jamban = "Jamban harus diisi!";
  if (!formData.ke_akte)
    errors.ke_akte = "Kepemilikan Akte Kelahiran harus diisi!";
  if (!formData.ke_ktp) errors.ke_ktp = "Kepemilikan KTP harus diisi!";
  if (!formData.darah) errors.darah = "Golongan Darah harus diisi!";
  if (!formData.bpjs_kesehatan)
    errors.bpjs_kesehatan = "BPJS kesehatan harus diisi!";

  return errors;
};

export const submitFormData = async (formData: any, isEdit: boolean) => {
  const url = isEdit
    ? `${process.env.REACT_APP_API_URL}/update_penduduk`
    : `${process.env.REACT_APP_API_URL}/penduduk`;

  try {
    // Check if formData is already FormData, else convert it
    const body = formData instanceof FormData ? formData : new FormData();
    if (!(formData instanceof FormData)) {
      for (const key in formData) {
        if (Object.prototype.hasOwnProperty.call(formData, key)) {
          body.append(key, formData[key]);
        }
      }
    }

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: body,
    });

    const responseText = await response.text(); // Get response text for more information
    if (!response.ok) {
      console.error("Error response:", responseText);
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    console.log("Form data submitted successfully!");
  } catch (error) {
    console.error("Error submitting form data:", error);
    throw error;
  }
};

export const submitSearch = async (data: any) => {
  const url = `${process.env.REACT_APP_API_URL}/cari_penduduk`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        token: process.env.REACT_APP_TOKEN, // Use your actual token here
        pnd_saatini: data.pnd_saatini,
        pekerjaan: data.pekerjaan,
        pnd_terakhir: data.pnd_terakhir,
        umur: data.umur,
      }),
    });

    const responseData = await response.json();

    return responseData;
  } catch (error) {
    console.error("Error submitting form data:", error);
    throw error;
  }
};
export const submitExcel = async (data: any) => {
  const url = `${process.env.REACT_APP_API_URL}/cetak_penduduk`;

  try {
    await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        token: process.env.REACT_APP_TOKEN,
        penduduk: data,
      }),
    });
  } catch (error) {
    console.error("Error submitting form data:", error);
    throw error;
  }
};
