export interface Inventaris {
  id_inven: number;
  nama: string;
  jenis: string;
  merek: string;
  jumlah: string;
  tahun: string;
  sumber: string;
  kondisi: string;
  harga: string;
  lokasi: string;
  des: string;
  status: string;
}

export const fetchInven = async (): Promise<Inventaris[]> => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/inven/${process.env.REACT_APP_TOKEN}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    }
  );
  const data = await response.json();

  return data;
};

export const filterData = (
  data: Inventaris[],
  search: string
): Inventaris[] => {
  return data.filter((inven: Inventaris) => {
    const nomor = inven.nama ? inven.nama.toLowerCase() : "";
    const lowerCaseSearch = search.toLowerCase();

    return nomor.includes(lowerCaseSearch);
  });
};
// Fungsi untuk menghitung total halaman
export const getTotalPages = (
  filteredData: Inventaris[],
  showCount: number
): number => {
  return Math.ceil(filteredData.length / showCount);
};

// Fungsi untuk mendapatkan data berdasarkan halaman saat ini
export const getPaginatedData = (
  filteredData: Inventaris[],
  currentPage: number,
  showCount: number
): Inventaris[] => {
  return filteredData.slice(
    (currentPage - 1) * showCount,
    currentPage * showCount
  );
};

export const fetchEditInventaris = async (id: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/edit_inven/${id}/${process.env.REACT_APP_TOKEN}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    );
    console.log(response);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error("Error fetching form data:", error);
    throw error;
  }
};

export const validateFormData = (formData: any) => {
  const errors: any = {};

  // Validasi untuk setiap field
  if (!formData.nama) errors.nama = "Nama Barang harus diisi!";
  if (!formData.jenis) errors.jenis = "Kategori Barang harus diisi!";
  if (!formData.jumlah) errors.jumlah = "Jumlah Barang harus diisi!";
  if (!formData.tahun) errors.tahun = "Tanggal Pengadaan harus diisi!";
  if (!formData.sumber) errors.sumber = "Sumber Perolehan diisi!";
  if (!formData.kondisi) errors.kondisi = "Kondisi Barang diisi!";
  if (!formData.harga) errors.harga = "Harga Barang diisi!";
  if (!formData.lokasi) errors.lokasi = "Lokasi Barang diisi!";
  if (!formData.status) errors.status = "Status Barang diisi!";

  return errors;
};

export const submitFormData = async (formData: any, isEdit: boolean) => {
  const url = isEdit
    ? `${process.env.REACT_APP_API_URL}/update_inven`
    : `${process.env.REACT_APP_API_URL}/minven`;
  console.log(formData);
  try {
    // Check if formData is already FormData, else convert it
    const body = formData instanceof FormData ? formData : new FormData();
    if (!(formData instanceof FormData)) {
      for (const key in formData) {
        if (Object.prototype.hasOwnProperty.call(formData, key)) {
          body.append(key, formData[key]);
        }
      }
    }

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: body,
    });

    const responseText = await response.text(); // Get response text for more information
    if (!response.ok) {
      console.error("Error response:", responseText);
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    console.log("Form data submitted successfully!");
  } catch (error) {
    console.error("Error submitting form data:", error);
    throw error;
  }
};
