import { useEffect, useState } from "react";
import {
  fetchEditLembaga,
  submitFormData,
  validateFormData,
} from "./Controller";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import LayoutBackend from "../../../../components/layouts/backend/Main";
import InputC from "../../../../components/layouts/backend/Input";
import FormC from "../../../../components/layouts/backend/Form";
import { useStateContext } from "../../../../middleware";

const AksiLembagaPage = () => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useStateContext();
  const [formData, setFormData] = useState({
    nama: "",

    user_id: 1,
  });

  const isEdit = window.location.pathname.includes("tambah_lembaga")
    ? false
    : Boolean(id);
  useEffect(() => {
    document.title = isEdit ? "Edit Lembaga " : "Tambah Lembaga ";
    if (id && window.location.pathname.includes("edit_lembaga")) {
      fetchEditLembaga(id)
        .then((data) => setFormData(data))
        .catch((error) => console.error("Error:", error));
    }
  }, [id, isEdit]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target as HTMLInputElement;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const validationErrors = validateFormData(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setLoading(false);
      return;
    }
    try {
      await submitFormData(formData, isEdit);
      navigate("/lembaga", {
        state: {
          message: isEdit
            ? "Data berhasil diperbarui!"
            : "Data berhasil ditambahkan!",
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  if (!token) {
    return <Navigate to="/login" />;
  }
  return (
    <>
      <LayoutBackend>
        <h5 className="card-title">
          {" "}
          {isEdit ? "Edit Lembaga" : "Tambah Lembaga"}{" "}
        </h5>
        <hr />
        <FormC onSubmit={handleSubmit}>
          <div className="row">
            <InputC
              type="text"
              col="col-md-12 mb-3"
              label="Nama Lembaga"
              name="nama"
              value={formData.nama}
              onChange={handleChange}
              placeholder="Nama Lembaga..."
              error={errors?.nama}
            />
          </div>
          <button
            type="submit"
            className="btn btn-primary btn-sm me-2"
            disabled={loading}
          >
            {loading ? "Loading..." : "Simpan"}
          </button>
          <Link to="/aparat" className="btn btn-danger btn-sm">
            Kembali
          </Link>
        </FormC>
      </LayoutBackend>
    </>
  );
};

export default AksiLembagaPage;
