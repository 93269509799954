/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

const LoginPage = () => {
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const validateFormData = (formData: any) => {
    const errors: any = {};

    if (!formData.username) errors.username = "Username harus diisi!";
    if (!formData.password) errors.password = "Password harus diisi!";

    return errors;
  };
  useEffect(() => {
    document.title = "Halaman Login";
  });
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formDataObj = Object.fromEntries(formData.entries());

    const validationErrors = validateFormData(formDataObj);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setIsLoading(false);
      return;
    }
    console.log(process.env.REACT_APP_API_URL);
    setIsLoading(true);
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          username: formDataObj.username,
          password: formDataObj.password,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data.status);
          if (data.status === 200) {
            sessionStorage.setItem("token", data.token);
            sessionStorage.setItem("user", data.id_user);
            sessionStorage.setItem("username", data.username);
            window.location.href = "dashboard";
          } else {
            setErrors({
              message: "Username atau password salah",
            });
            setIsLoading(false);
          }
        });
    } catch (error) {
      // console.error("Error submitting form data:", error);
      setErrors("Terjadi kesalahan. Silahkan coba lagi.");
      setIsLoading(false);
    }
  };
  return (
    <>
      {/* Required meta tags */}
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link
        rel="icon"
        href="assets/images/favicon-32x32.png"
        type="image/png"
      />
      {/* Bootstrap CSS */}
      <link href="assets/css/bootstrap.min.css" rel="stylesheet" />
      <link href="assets/css/bootstrap-extended.css" rel="stylesheet" />
      <link href="assets/css/style.css" rel="stylesheet" />
      <link href="assets/css/icons.css" rel="stylesheet" />
      <link
        href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap"
        rel="stylesheet"
      />
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css"
      />
      {/* loader*/}
      <link href="assets/css/pace.min.css" rel="stylesheet" />
      <title>Onedash - Bootstrap 5 Admin Template</title>
      {/*start wrapper*/}
      <div className="wrapper">
        <header>
          <nav className="navbar navbar-expand-lg navbar-light bg-white rounded-0 border-bottom">
            <div className="container">
              <a className="navbar-brand" href="#">
                <img src="assets/images/brand-logo-2.png" width={140} alt="" />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav mb-2 mb-lg-0 align-items-center">
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      About
                    </a>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle dropdown-toggle-nocaret"
                      href="#"
                      data-bs-toggle="dropdown"
                    >
                      Services{" "}
                      <i className="bi bi-chevron-down align-middle ms-2" />
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="#">
                          Action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Another action
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Something else here
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      Contact Us
                    </a>
                  </li>
                </ul>
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle dropdown-toggle-nocaret"
                      href="#"
                      data-bs-toggle="dropdown"
                    >
                      English
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="#">
                          Action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Another action
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Something else here
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      Support
                    </a>
                  </li>
                </ul>
                <div className="d-flex ms-3 gap-3">
                  <a
                    href="authentication-signin-with-header-footer.html"
                    className="btn btn-primary btn-sm px-4 radius-30"
                  >
                    Login
                  </a>
                  <a
                    href="authentication-signup-with-header-footer.html"
                    className="btn btn-white btn-sm px-4 radius-30"
                  >
                    Register
                  </a>
                </div>
              </div>
            </div>
          </nav>
        </header>
        {/*start content*/}
        <main className="authentication-content">
          <div className="container">
            <div className="mt-5">
              <div className="card rounded-0 overflow-hidden shadow-none border mb-5 mb-lg-0">
                <div className="row g-0">
                  <div className="col-12 order-1 col-xl-8 d-none d-xl-flex align-items-center justify-content-center border-end">
                    <img
                      src="assets/images/error/auth-img-7.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="col-12 col-xl-4 order-xl-2">
                    <div className="card-body p-4 p-sm-5">
                      <h5 className="card-title">Login</h5>
                      <p className="card-text mb-4">
                        Silahkan Login dengan akun yang sudah terdaftar
                      </p>
                      {errors.message && (
                        <div className="alert border-0 bg-danger alert-dismissible fade show py-2">
                          <div className="d-flex align-items-center">
                            <div className="fs-3 text-white">
                              <i className="bi bi-x-circle-fill"></i>
                            </div>
                            <div className="ms-3">
                              <div className="text-white">{errors.message}</div>
                            </div>
                          </div>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="alert"
                            aria-label="Close"
                          ></button>
                        </div>
                      )}

                      <form className="form-body" onSubmit={handleSubmit}>
                        <div className="row g-3">
                          <div className="col-12">
                            <label htmlFor="username" className="form-label">
                              Userame
                            </label>
                            <div className="ms-auto position-relative">
                              <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                                <i className="bi bi-person-fill" />
                              </div>
                              <input
                                type="text"
                                className="form-control radius-30 ps-5"
                                id="username"
                                placeholder="Enter Username"
                                name="username"
                              />
                              <span className="text-danger">
                                {errors.username}
                              </span>
                            </div>
                          </div>
                          <div className="col-12">
                            <label htmlFor="Password" className="form-label">
                              Enter Password
                            </label>
                            <div className="ms-auto position-relative">
                              <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                                <i className="bi bi-lock-fill" />
                              </div>
                              <input
                                type="password"
                                className="form-control radius-30 ps-5"
                                id="Password"
                                placeholder="Password"
                                name="password"
                              />{" "}
                              <span className="text-danger">
                                {errors.password}
                              </span>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="flexSwitchCheckChecked"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexSwitchCheckChecked"
                              >
                                Remember Me
                              </label>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="d-grid">
                              <button
                                type="submit"
                                className="btn btn-primary radius-30"
                              >
                                {isLoading ? "Loading..." : "Login"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        {/*end page main*/}
        <footer className="bg-white border-top p-3 text-center fixed-bottom">
          <p className="mb-0">Copyright © 2021. All right reserved.</p>
        </footer>
      </div>
      <Helmet>
        <script src="assets/js/jquery.min.js"></script>
        <script src="assets/js/pace.min.js"></script>
      </Helmet>
    </>
  );
};

export default LoginPage;
