export interface Lkd {
  id_lkd: number;
  nama: string;
  alamat: string;
  no_sk: string;
  pengurus: string;
}

export const fetchLkd = async (): Promise<Lkd[]> => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/lkd/${process.env.REACT_APP_TOKEN}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    }
  );
  const data = await response.json();

  return data;
};

export const filterData = (data: Lkd[], search: string): Lkd[] => {
  return data.filter((lkd: Lkd) => {
    const nomor = lkd.nama ? lkd.nama.toLowerCase() : "";
    const lowerCaseSearch = search.toLowerCase();

    return nomor.includes(lowerCaseSearch);
  });
};
// Fungsi untuk menghitung total halaman
export const getTotalPages = (
  filteredData: Lkd[],
  showCount: number
): number => {
  return Math.ceil(filteredData.length / showCount);
};

// Fungsi untuk mendapatkan data berdasarkan halaman saat ini
export const getPaginatedData = (
  filteredData: Lkd[],
  currentPage: number,
  showCount: number
): Lkd[] => {
  return filteredData.slice(
    (currentPage - 1) * showCount,
    currentPage * showCount
  );
};

export const fetchEditLkd = async (id: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/edit_lkd/${id}/${process.env.REACT_APP_TOKEN}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    );
    console.log(response);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error("Error fetching form data:", error);
    throw error;
  }
};

export const validateFormData = (formData: any) => {
  const errors: any = {};

  // Validasi untuk setiap field
  if (!formData.lembaga_id) errors.lembaga_id = "Lembaga harus diisi!";
  if (!formData.pengurus) errors.pengurus = "Nama Pengurus harus diisi!";
  if (!formData.alamat) errors.alamat = "Alamat harus diisi!";
  if (!formData.no_sk) errors.no_sk = "No SK Pengurus harus diisi!";

  return errors;
};

export const submitFormData = async (formData: any, isEdit: boolean) => {
  const url = isEdit
    ? `${process.env.REACT_APP_API_URL}/update_lkd`
    : `${process.env.REACT_APP_API_URL}/mlkd`;

  try {
    // Check if formData is already FormData, else convert it
    const body = formData instanceof FormData ? formData : new FormData();
    if (!(formData instanceof FormData)) {
      for (const key in formData) {
        if (Object.prototype.hasOwnProperty.call(formData, key)) {
          body.append(key, formData[key]);
        }
      }
    }

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: body,
    });

    const responseText = await response.text(); // Get response text for more information
    if (!response.ok) {
      console.error("Error response:", responseText);
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    console.log("Form data submitted successfully!");
  } catch (error) {
    console.error("Error submitting form data:", error);
    throw error;
  }
};
