import { Navigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LayoutBackend from "../../../../components/layouts/backend/Main";
import { Ekspedisi, fetchEditEkspedisi } from "./Controller";
import { formatTanggalIndonesia } from "../../../Helper";
import { useStateContext } from "../../../../middleware";

const DetailEkspedisiPage = () => {
  const { id } = useParams();
  const [data, setData] = useState<Ekspedisi>();
  const [loading, setLoading] = useState(true);
  const { token } = useStateContext();
  useEffect(() => {
    document.title = "Detail Data Buku Ekspedisi";
    const loadData = async () => {
      try {
        const data = await fetchEditEkspedisi(
          window.location.pathname.split("/")[2]
        );
        setData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [data]);
  if (!token) return <Navigate to="/login" />;
  return (
    <>
      <LayoutBackend>
        <div className="row">
          <div className="col-12 col-lg-8">
            <div className="card shadow-sm border-0">
              <div className="card-body">
                <h5 className="mb-0">Informasi Surat Masuk</h5>
                <hr />
                <div className="card shadow-none border">
                  <div className="card-body">
                    {loading ? (
                      <div>Loading...</div>
                    ) : (
                      <>
                        <table className="table table-hover table-sm table-striped table-bordered">
                          <tbody>
                            <tr>
                              <td>Tanggal Surat</td>
                              <td>
                                :{formatTanggalIndonesia(`${data?.tgl_surat}`)}
                              </td>
                            </tr>
                            <tr>
                              <td>Nomor Surat</td>
                              <td>: {data?.nomor}</td>
                            </tr>
                            <tr>
                              <td>Penerima Surat </td>{" "}
                              <td>: {data?.penerima}</td>
                            </tr>
                            <tr>
                              <td>Alamat Surat</td> <td>: {data?.alamat}</td>
                            </tr>
                            <tr>
                              <td>Perihal</td>
                              <td>: {data?.perihal}</td>
                            </tr>
                            <tr>
                              <td>Tanggal Penerima</td>
                              <td>: {data?.tgl}</td>
                            </tr>
                            <tr>
                              <td>Keterangan</td>
                              <td>: {data?.ket}</td>
                            </tr>
                          </tbody>
                        </table>
                      </>
                    )}
                  </div>
                </div>
                <Link to="/ekspedisi" className="btn btn-sm btn-outline-danger">
                  Kembali
                </Link>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="card shadow-sm border-0 overflow-hidden">
              <div className="card-body">
                <div className="profile-avatar text-center">
                  <div className="row">
                    <div className="col-md-6">
                      <h5 className="text-uppercase">Paraf Petugas</h5>
                      {data?.paraf !== null ? (
                        <>
                          <Link
                            to={`${process.env.REACT_APP_API_IMAGE}/${data?.paraf}`}
                            target="_blank"
                          >
                            <img
                              src={`${process.env.REACT_APP_API_IMAGE}/${data?.paraf}`}
                              width={120}
                              height={120}
                              alt=""
                            />
                          </Link>
                        </>
                      ) : (
                        <>
                          <img
                            src="/default.jpg"
                            width={120}
                            height={120}
                            alt=""
                          />
                        </>
                      )}
                    </div>
                    <div className="col-md-6">
                      <h5 className="text-uppercase">Surat Masuk</h5>
                      {data?.paraf !== null ? (
                        <>
                          <Link
                            to={`${process.env.REACT_APP_API_IMAGE}/${data?.paraf}`}
                            target="_blank"
                          >
                            <img
                              src={`${process.env.REACT_APP_API_IMAGE}/${data?.paraf}`}
                              width={120}
                              height={120}
                              alt=""
                            />
                          </Link>
                        </>
                      ) : (
                        <>
                          <img
                            src="/default.jpg"
                            width={120}
                            height={120}
                            alt=""
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutBackend>
    </>
  );
};

export default DetailEkspedisiPage;
