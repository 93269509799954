import { Navigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchEditRencana, Rencana } from "../Controller";
import LayoutBackend from "../../../../../components/layouts/backend/Main";
import { useStateContext } from "../../../../../middleware";

const DetailKegiatan = () => {
  const { id } = useParams();
  const [data, setData] = useState<Rencana>();
  const [loading, setLoading] = useState(true);
  const { token } = useStateContext();
  // const { token } = useStateContext();
  // Mengambil data dari API
  useEffect(() => {
    document.title = "Detail Data Anggota";
    const loadData = async () => {
      try {
        const data = await fetchEditRencana(
          window.location.pathname.split("/")[2]
        );
        setData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [data]);
  if (!token) return <Navigate to="/login" />;
  return (
    <>
      <LayoutBackend>
        <div className="row">
          <div className="col-12 col-lg-12">
            <div className="card shadow-sm border-0">
              <div className="card-body">
                <h5 className="mb-0">Informasi Kegiatan Pembangunan</h5>
                <hr />
                <div className="card shadow-none border">
                  <div className="card-body">
                    {loading ? (
                      <div>Loading...</div>
                    ) : (
                      <>
                        <table className="table table-hover table-sm table-striped table-bordered">
                          <tbody>
                            <tr>
                              <td>Uraian</td> <td> {data?.uraian}</td>
                            </tr>
                            <tr>
                              <td>Lokasi Kegiatan Pembangunan</td>
                              <td>{data?.lokasi}</td>
                            </tr>
                            <tr>
                              <td>Anggaran </td> <td> {data?.anggaran}</td>
                            </tr>
                            <tr>
                              <td>Sumber Dana </td> <td> {data?.sumber}</td>
                            </tr>
                            <tr>
                              <td>Status Kegiatan Pembangunan </td>
                              <td>{data?.status}</td>
                            </tr>
                          </tbody>
                        </table>
                      </>
                    )}
                  </div>
                </div>
                <Link to="/kegiatan" className="btn btn-sm btn-outline-danger">
                  Kembali
                </Link>
              </div>
            </div>
          </div>
        </div>
      </LayoutBackend>
    </>
  );
};

export default DetailKegiatan;
