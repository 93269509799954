import LabelC from "./Label";

const TextareaC = (props: any) => {
  const { name, placeholder, value, onChange, label, col, error } = props;
  return (
    <>
      <div className={col}>
        <LabelC>{label} </LabelC>
        <textarea
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          className="form-control"
        ></textarea>
        <span className="text-danger">{error}</span>
      </div>
    </>
  );
};
export default TextareaC;
