import { useEffect, useState } from "react";
import {
  fetchEditKekayaan,
  submitFormData,
  validateFormData,
} from "./Controller";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import LayoutBackend from "../../../../components/layouts/backend/Main";
import InputC from "../../../../components/layouts/backend/Input";
import FormC from "../../../../components/layouts/backend/Form";
import SelectC from "../../../../components/layouts/backend/Select";
import { useStateContext } from "../../../../middleware";

const AksiKekayaan = () => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [image, setImage] = useState<any>(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useStateContext();
  const [formData, setFormData] = useState({
    nama: "",
    jenis: "",
    luas: "",
    lokasi: "",
    tahun: "",
    harga: "",
    sumber: "",
    manfaat: "",
    status: "",
    kondisi: "",
    sk: new File([], ""),
    user_id: 1,
  });

  const isEdit = window.location.pathname.includes("tambah_kekayaan")
    ? false
    : Boolean(id);
  useEffect(() => {
    document.title = isEdit ? "Edit Kekayaan Desa" : "Tambah Kekayaan Desa";
    if (id && window.location.pathname.includes("edit_kekayaan")) {
      fetchEditKekayaan(id)
        .then((data) => setFormData(data))
        .catch((error) => console.error("Error:", error));
    }
  }, [id, isEdit]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, files } = e.target as HTMLInputElement;
    if (files && files.length > 0) {
      const file = files[0];
      if (file.size > 2 * 1024 * 1024) {
        // Memeriksa apakah ukuran file melebihi 2MB
        alert("Ukuran file tidak boleh melebihi 2MB!");
        return; // Menghentikan pemrosesan lebih lanjut
      }
      // Membuat URL sementara untuk prapratampilan gambar
      const imageUrl = URL.createObjectURL(file);
      setImage(imageUrl);
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    console.log(formData);
    const validationErrors = validateFormData(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setLoading(false);
      return;
    }
    try {
      await submitFormData(formData, isEdit);
      navigate("/kekayaan", {
        state: {
          message: isEdit
            ? "Data berhasil diperbarui!"
            : "Data berhasil ditambahkan!",
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  if (!token) return <Navigate to="/login" />;
  return (
    <>
      <LayoutBackend>
        <h5 className="card-title">
          {" "}
          {isEdit ? "Edit Kekayaan Desa" : "Tambah Kekayaan Desa"}{" "}
        </h5>
        <hr />
        <FormC onSubmit={handleSubmit}>
          <div className="row">
            <SelectC
              col="col-6"
              label="Jenis Kekayaan"
              name="jenis"
              onChange={handleChange}
              value={formData.jenis}
              error={errors?.jenis}
            >
              <option value="Tanah">Tanah</option>
              <option value="Bangunan">Bangunan</option>
              <option value="Peralatan">Peralatan</option>
              <option value="Kendaraan">Kendaraan</option>
            </SelectC>
            <InputC
              type="text"
              col="col-md-6 mb-3"
              label="Deskripsi Kekayaan"
              name="nama"
              value={formData.nama}
              onChange={handleChange}
              placeholder="Deskripsi Kekayaan..."
              error={errors?.nama}
            />

            <InputC
              type="text"
              col="col-md-6 mb-3"
              label="Luas"
              name="luas"
              value={formData.luas}
              onChange={handleChange}
              error={errors?.luas}
              placeholder="Luas..."
            />
            <InputC
              type="text"
              col="col-md-6 mb-3"
              label="Lokasi Barang"
              name="lokasi"
              value={formData.lokasi}
              onChange={handleChange}
              placeholder="Lokasi ..."
              error={errors?.lokasi}
            />
            <InputC
              type="text"
              col="col-md-6 mb-3"
              label="Tahun Perolehan"
              name="tahun"
              value={formData.tahun}
              placeholder="Tahun Perolehan..."
              onChange={handleChange}
              error={errors?.tahun}
            />
            <InputC
              type="text"
              col="col-md-6 mb-3"
              label="Nilai Kekayaan"
              name="harga"
              value={formData.harga}
              onChange={handleChange}
              placeholder="Nilai Kekayaan..."
              error={errors?.harga}
            />
            <InputC
              type="text"
              col="col-md-6 mb-3"
              label="Sumber Dana"
              name="sumber"
              value={formData.sumber}
              onChange={handleChange}
              placeholder="Sumber Perolehan..."
              error={errors?.sumber}
            />
            <InputC
              type="text"
              col="col-md-6 mb-3"
              label="Pemanfaatan"
              name="manfaat"
              value={formData.manfaat}
              onChange={handleChange}
              placeholder="Pemanfaatan..."
              error={errors?.manfaat}
            />
            <SelectC
              col="col-6 mb-3"
              label="Status Kepemilikan"
              name="status"
              onChange={handleChange}
              value={formData.status}
              error={errors?.status}
            >
              <option value="Milik Desa">Milik Desa</option>
              <option value="Hak Guna">Hak Guna</option>
              <option value="Sewa">Sewa</option>
              <option value="Pinjam Pakai">Pinjam Pakai</option>
              <option value="Hibah">Hibah</option>
              <option value="Kerjasama Operasional">
                Kerjasama Operasional
              </option>
              <option value="Milik Pemerintah">Milik Pemerintah</option>
              <option value="Milik Masyarakat">Milik Masyarakat</option>
            </SelectC>
            <SelectC
              col="col-6"
              label="Kondisi Fisik"
              name="kondisi"
              onChange={handleChange}
              value={formData.kondisi}
              error={errors?.kondisi}
            >
              <option value="Baik">Baik</option>
              <option value="Cukup">Cukup</option>
              <option value="Rusak Ringan">Rusak Ringan</option>
              <option value="Rusak Berat">Rusak Berat</option>
              <option value="Tidak Layak Pakai">Tidak Layak Pakai</option>
              <option value="Baru">Baru</option>
              <option value="Bekas">Bekas</option>
            </SelectC>
            <InputC
              type="file"
              col="col-md-12"
              label="Upload Bukti "
              name="sk"
              onChange={handleChange}
            />
            {image && (
              <img
                src={image}
                alt="preview"
                style={{ width: "20%", height: "auto" }}
              />
            )}
            {isEdit && <InputC type="hidden" name="sk" value={formData.sk} />}
          </div>
          <button
            type="submit"
            className="btn btn-primary btn-sm me-2 mt-3"
            disabled={loading}
          >
            {loading ? "Loading..." : "Simpan"}
          </button>
          <Link to="/kekayaan" className="btn btn-danger btn-sm mt-3">
            Kembali
          </Link>
        </FormC>
      </LayoutBackend>
    </>
  );
};

export default AksiKekayaan;
