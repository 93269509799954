export interface Ihp {
  id_ihp: number;
  nama: string;
  jenis: string;
  lokasi: string;
  tgl: string;
  anggaran: string;
  kondisi: string;
  sumber: string;
  volume: string;
  nomor: string;
  pemilik: string;
  nilai: string;
  status: string;
  ket: string;
  dokumen: string;
  penanggungjawab: string;
}

export const fetchInven = async (): Promise<Ihp[]> => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/ihp/${process.env.REACT_APP_TOKEN}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    }
  );
  const data = await response.json();

  return data;
};

export const filterData = (data: Ihp[], search: string): Ihp[] => {
  return data.filter((inven: Ihp) => {
    const nomor = inven.nama ? inven.nama.toLowerCase() : "";
    const lowerCaseSearch = search.toLowerCase();

    return nomor.includes(lowerCaseSearch);
  });
};
// Fungsi untuk menghitung total halaman
export const getTotalPages = (
  filteredData: Ihp[],
  showCount: number
): number => {
  return Math.ceil(filteredData.length / showCount);
};

// Fungsi untuk mendapatkan data berdasarkan halaman saat ini
export const getPaginatedData = (
  filteredData: Ihp[],
  currentPage: number,
  showCount: number
): Ihp[] => {
  return filteredData.slice(
    (currentPage - 1) * showCount,
    currentPage * showCount
  );
};

export const fetchEditIhp = async (id: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/edit_ihp/${id}/${process.env.REACT_APP_TOKEN}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    );
    console.log(response);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error("Error fetching form data:", error);
    throw error;
  }
};

export const validateFormData = (formData: any) => {
  const errors: any = {};

  // Validasi untuk setiap field
  if (!formData.nama) errors.nama = "Nama harus diisi!";
  if (!formData.jenis) errors.jenis = "Jenis harus diisi!";
  if (!formData.lokasi) errors.lokasi = "Lokasi harus diisi!";
  if (!formData.tgl) errors.tgl = "Tanggal harus diisi!";
  if (!formData.anggaran) errors.anggaran = "Anggaran harus diisi!";
  if (!formData.kondisi) errors.kondisi = "Kondisi harus diisi!";
  if (!formData.sumber) errors.sumber = "Sumber harus diisi!";
  if (!formData.volume) errors.volume = "Volume harus diisi!";
  if (!formData.nomor) errors.nomor = "Nomor harus diisi!";
  if (!formData.pemilik) errors.pemilik = "Pemilik harus diisi!";
  if (!formData.nilai) errors.nilai = "Nilai harus diisi!";
  if (!formData.status) errors.status = "Status harus diisi!";
  if (!formData.ket) errors.ket = "Keterangan harus diisi!";
  if (!formData.penanggungjawab)
    errors.penanggungjawab = "Penanggungjawab harus diisi!";

  return errors;
};

export const submitFormData = async (formData: any, isEdit: boolean) => {
  const url = isEdit
    ? `${process.env.REACT_APP_API_URL}/update_ihp`
    : `${process.env.REACT_APP_API_URL}/mihp`;

  try {
    // Check if formData is already FormData, else convert it
    const body = formData instanceof FormData ? formData : new FormData();
    if (!(formData instanceof FormData)) {
      Object.entries(formData).forEach(([key, value]) => {
        body.append(key, value as string | Blob);
      });
    }

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: body,
    });

    const responseText = await response.text(); // Get response text for more information
    if (!response.ok) {
      console.error("Error response:", responseText);
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    console.log("Form data submitted successfully!");
  } catch (error) {
    console.error("Error submitting form data:", error);
    throw error;
  }
};
