const FormC = (props: any) => {
  const { children, onSubmit } = props;
  return (
    <>
      <div className="card">
        <div className="card-body">
          <form onSubmit={onSubmit}>{children}</form>
        </div>
      </div>
    </>
  );
};

export default FormC;
