import LabelC from "./Label";

const SelectC = (props: any) => {
  const { children, name, onChange, value, label, col, error } = props;
  return (
    <>
      <div className={col}>
        <LabelC>{label}</LabelC>
        <select
          className="form-control"
          name={name}
          id={name}
          onChange={onChange}
          value={value}
        >
          <option value="">Pilih</option>
          {children}
        </select>
        <span className="text-danger">{error}</span>
      </div>
    </>
  );
};
export default SelectC;
