import { useEffect, useState } from "react";
import {
  fetchEditInventaris,
  submitFormData,
  validateFormData,
} from "./Controller";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import LayoutBackend from "../../../../components/layouts/backend/Main";
import InputC from "../../../../components/layouts/backend/Input";
import TextareaC from "../../../../components/layouts/backend/Textarea";
import FormC from "../../../../components/layouts/backend/Form";
import SelectC from "../../../../components/layouts/backend/Select";
import { useStateContext } from "../../../../middleware";

const AksiInventaris = () => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useStateContext();
  const [formData, setFormData] = useState({
    nama: "",
    jenis: "",
    merek: "",
    jumlah: "",
    tahun: "",
    sumber: "",
    kondisi: "",
    harga: "",
    lokasi: "",
    des: "",
    status: "",
    user_id: 1,
  });

  const isEdit = window.location.pathname.includes("tambah_kk")
    ? false
    : Boolean(id);
  useEffect(() => {
    document.title = isEdit ? "Edit Inventaris Desa" : "Tambah Inventaris Desa";
    if (id && window.location.pathname.includes("edit_inven")) {
      fetchEditInventaris(id)
        .then((data) => setFormData(data))
        .catch((error) => console.error("Error:", error));
    }
  }, [id, isEdit]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target as HTMLInputElement;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const validationErrors = validateFormData(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setLoading(false);
      return;
    }
    try {
      await submitFormData(formData, isEdit);
      navigate("/inventaris", {
        state: {
          message: isEdit
            ? "Data berhasil diperbarui!"
            : "Data berhasil ditambahkan!",
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  if (!token) return <Navigate to="/login" />;
  return (
    <>
      <LayoutBackend>
        <h5 className="card-title">
          {" "}
          {isEdit ? "Edit Inventaris Desa" : "Tambah Inventaris Desa"}{" "}
        </h5>
        <hr />
        <FormC onSubmit={handleSubmit}>
          <div className="row">
            <InputC
              type="text"
              col="col-md-6 mb-3"
              label="Nama Barang"
              name="nama"
              value={formData.nama}
              onChange={handleChange}
              placeholder="Nama Barang..."
              error={errors?.nama}
            />
            <SelectC
              col="col-6"
              label="Kategori Barang"
              name="jenis"
              onChange={handleChange}
              value={formData.jenis}
              error={errors?.jenis}
            >
              <option value="Kantor">Kantor</option>
              <option value="Kendaraan">Kendaraan</option>
              <option value="Mesin">Mesin</option>
              <option value="Pertanian">Pertanian</option>
            </SelectC>
            <InputC
              type="text"
              col="col-md-6 mb-3"
              label="Merek / Model Barang"
              name="merek"
              value={formData.merek}
              onChange={handleChange}
              placeholder="Merek / Model Barang..."
            />
            <InputC
              type="text"
              col="col-md-6 mb-3"
              label="Jumlah Barang"
              name="jumlah"
              value={formData.jumlah}
              onChange={handleChange}
              placeholder="Jumlah Barang..."
              error={errors?.jumlah}
            />
            <InputC
              type="text"
              col="col-md-6 mb-3"
              label="Tahun Perolehan"
              name="tahun"
              value={formData.tahun}
              onChange={handleChange}
              error={errors?.tahun}
            />
            <InputC
              type="text"
              col="col-md-6 mb-3"
              label="Sumber Perolehan"
              name="sumber"
              value={formData.sumber}
              onChange={handleChange}
              placeholder="Sumber Perolehan..."
              error={errors?.sumber}
            />
            <InputC
              type="text"
              col="col-md-6 mb-3"
              label="Kondisi Barang"
              name="kondisi"
              value={formData.kondisi}
              onChange={handleChange}
              placeholder="Kondisi Barang..."
              error={errors?.kondisi}
            />
            <InputC
              type="text"
              col="col-md-6 mb-3"
              label="Harga Barang"
              name="harga"
              value={formData.harga}
              onChange={handleChange}
              placeholder="Harga Barang..."
              error={errors?.harga}
            />
            <InputC
              type="text"
              col="col-md-6 mb-3"
              label="Lokasi Barang"
              name="lokasi"
              value={formData.lokasi}
              onChange={handleChange}
              placeholder="Lokasi Barang..."
              error={errors?.lokasi}
            />
            <SelectC
              col="col-6"
              label="Status Barang"
              name="status"
              onChange={handleChange}
              value={formData.status}
              error={errors?.status}
            >
              <option value="Aktif digunakan">Aktif digunakan</option>
              <option value="Disimpan">Disimpan</option>
              <option value="Dinonaktifkan">Dinonaktifkan</option>
            </SelectC>
            <TextareaC
              col="col-md-12 mb-3"
              label="Keterangan Tambahan"
              name="des"
              value={formData.des}
              onChange={handleChange}
              placeholder="Keterangan Tambahan..."
            />
          </div>
          <button
            type="submit"
            className="btn btn-primary btn-sm me-2"
            disabled={loading}
          >
            {loading ? "Loading..." : "Simpan"}
          </button>
          <Link to="/inventaris" className="btn btn-danger btn-sm">
            Kembali
          </Link>
        </FormC>
      </LayoutBackend>
    </>
  );
};

export default AksiInventaris;
