import { useEffect, useState } from "react";
import LayoutBackend from "../../../components/layouts/backend/Main";
import {
  fetchUmur,
  Penduduk,
  submitSearch,
} from "../../master/penduduk/Controller";
import { Link, Navigate } from "react-router-dom";
import { exportToExcel } from "../../../components/layouts/backend/Excel";
import { useStateContext } from "../../../middleware";

const RekapitulasiPage: React.FC = () => {
  const [umur, setUmur] = useState<Penduduk[]>([]);
  const [penduduk, setPenduduk] = useState<Penduduk[]>([]);
  const [loading, setLoading] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const { token } = useStateContext();
  const [formData, setFormData] = useState({
    pekerjaan: "",
    pnd_saatini: "",
    pnd_terakhir: "",
    umur: "",
    cetak: false,
  });

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const data = await fetchUmur();
      setUmur(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    setSubmitted(true);
    try {
      const data = await submitSearch(formData);
      setPenduduk(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCetak = () => {
    exportToExcel(penduduk, "Rekapitulasi");
  };
  if (!token) return <Navigate to="/login" />;
  return (
    <>
      <LayoutBackend>
        <div className="col-sm-12">
          <div className="d-flex justify-content-between align-items-center">
            {!submitted ? (
              <h6 className="mb-0 text-uppercase">REKAP DATA PENDUDUK</h6>
            ) : (
              <h6 className="mb-0 text-uppercase">
                HASIL PENCARIAN
                {formData.pekerjaan
                  ? ` PEKERJAAN ${formData.pekerjaan}`
                  : formData.umur
                  ? ` UMUR ${formData.umur}`
                  : formData.pnd_saatini
                  ? ` PENDIDIKAN SAAT INI ${formData.pnd_saatini}`
                  : formData.pnd_terakhir
                  ? ` PENDIDIKAN TERAKHIR ${formData.pnd_terakhir}`
                  : " TANPA FILTER"}{" "}
                : {penduduk.length}
              </h6>
            )}
            {!submitted ? null : (
              <>
                <div className="d-flex">
                  <Link
                    to="#"
                    className="btn btn-primary me-2"
                    onClick={handleCetak}
                  >
                    <i className="bi bi-printer"></i> Cetak
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-danger"
                    onClick={() => setSubmitted(false)}
                  >
                    <i className="bi bi-arrow-left"></i> Kembali
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
        <hr />
        <div className="card">
          <div className="card-body">
            {!submitted ? (
              <div className="border p-3 rounded">
                <h6 className="mb-0 text-uppercase">
                  Rekapitulasi Penduduk berdasarkan Pendidikan, Pekerjaan, dan
                  Umur
                </h6>
                <hr />
                <form className="row g-3" onSubmit={handleSubmit}>
                  <div className="col-md-2">
                    <label className="form-label">Pendidikan Saat ini</label>
                    <select
                      className="form-control"
                      name="pnd_saatini"
                      onChange={handleChange}
                      value={formData.pnd_saatini}
                    >
                      <option value="">Pilih</option>
                      <option value="SD/Sederajat">SD/Sederajat</option>
                      <option value="SMP">SMP</option>
                      <option value="SMA">SMA</option>
                      <option value="D1">D1</option>
                      <option value="D2">D2</option>
                      <option value="D3">D3</option>
                      <option value="S1">S1</option>
                      <option value="S2">S2</option>
                      <option value="S3">S3</option>
                    </select>
                  </div>
                  <div className="col-md-2">
                    <label className="form-label">Pendidikan Terakhir</label>
                    <select
                      className="form-control"
                      name="pnd_terakhir"
                      onChange={handleChange}
                      value={formData.pnd_terakhir}
                    >
                      <option value="">Pilih</option>
                      <option value="SD/Sederajat">SD/Sederajat</option>
                      <option value="SMP">SMP</option>
                      <option value="SMA">SMA</option>
                      <option value="D1">D1</option>
                      <option value="D2">D2</option>
                      <option value="D3">D3</option>
                      <option value="S1">S1</option>
                      <option value="S2">S2</option>
                      <option value="S3">S3</option>
                    </select>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">Pekerjaan</label>
                    <select
                      className="form-control"
                      name="pekerjaan"
                      onChange={handleChange}
                      value={formData.pekerjaan}
                    >
                      <option value="">Pilih</option>
                      <option value="PNS/TNI/POLRI">PNS/TNI/POLRI</option>
                      <option value="Karyawan Swasta">Karyawan Swasta</option>
                      <option value="Wiraswasta">Wiraswasta</option>
                      <option value="Petani">Petani</option>
                      <option value="Buruh">Buruh</option>
                      <option value="Pensiunan">Pensiunan</option>
                      <option value="Lainnya">Lainnya</option>
                    </select>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">Umur</label>
                    <select
                      className="form-control"
                      name="umur"
                      onChange={handleChange}
                      value={formData.umur}
                    >
                      <option value="">Pilih</option>
                      {umur.map((item) => (
                        <option key={item.umur} value={item.umur}>
                          {item.umur}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-1">
                    <div className="d-grid">
                      <button type="submit" className="btn btn-primary">
                        Rekap
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            ) : (
              <div>
                {loading ? (
                  <p>Loading...</p>
                ) : penduduk.length > 0 ? (
                  <div className="table-responsive">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Nama</th>
                          <th>NIK</th>
                          <th>Pendidikan Saat Ini</th>
                          <th>Pendidikan Terakhir</th>
                          <th>Pekerjaan</th>
                          <th>Umur</th>
                        </tr>
                      </thead>
                      <tbody>
                        {penduduk.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.nama}</td>
                            <td>{item.nik}</td>
                            <td>{item.pend_saatini}</td>
                            <td>{item.pnd_terakhir}</td>
                            <td>{item.pekerjaan}</td>
                            <td>{item.umur}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <p>Tidak ada data</p>
                )}
              </div>
            )}
          </div>
        </div>
      </LayoutBackend>
    </>
  );
};

export default RekapitulasiPage;
