import { useEffect, useState } from "react";
import { fetchEditIhp, submitFormData, validateFormData } from "./Controller";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import LayoutBackend from "../../../../components/layouts/backend/Main";
import InputC from "../../../../components/layouts/backend/Input";
import FormC from "../../../../components/layouts/backend/Form";
import SelectC from "../../../../components/layouts/backend/Select";
import { useStateContext } from "../../../../middleware";

const AksiIhpPage = () => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [image, setImage] = useState<any>(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useStateContext();
  const [formData, setFormData] = useState({
    nama: "",
    jenis: "",
    lokasi: "",
    tgl: "",
    anggaran: "",
    kondisi: "",
    sumber: "",
    volume: "",
    nomor: "",
    pemilik: "",
    nilai: "",
    status: "",
    ket: "",
    dokumen: new File([], ""),
    penanggungjawab: "",
    user_id: 1,
  });

  const isEdit = window.location.pathname.includes("tambah_ihp")
    ? false
    : Boolean(id);
  useEffect(() => {
    document.title = isEdit
      ? "Edit Inventarisasi Hasil Pembangunan"
      : "Tambah Inventarisasi Hasil Pembangunan";
    if (id && window.location.pathname.includes("edit_ihp")) {
      fetchEditIhp(id)
        .then((data) => setFormData(data))
        .catch((error) => console.error("Error:", error));
    }
  }, [id, isEdit]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, files } = e.target as HTMLInputElement;
    if (files && files.length > 0) {
      const file = files[0];
      if (file.size > 2 * 1024 * 1024) {
        // Memeriksa apakah ukuran file melebihi 2MB
        alert("Ukuran file tidak boleh melebihi 2MB!");
        return; // Menghentikan pemrosesan lebih lanjut
      }
      // Membuat URL sementara untuk prapratampilan gambar
      const imageUrl = URL.createObjectURL(file);
      setImage(imageUrl);
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    console.log(formData);
    const validationErrors = validateFormData(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setLoading(false);
      return;
    }
    try {
      await submitFormData(formData, isEdit);
      navigate("/ihp", {
        state: {
          message: isEdit
            ? "Data berhasil diperbarui!"
            : "Data berhasil ditambahkan!",
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  if (!token) {
    return <Navigate to="/login" />;
  }
  return (
    <>
      <LayoutBackend>
        <h5 className="card-title">
          {" "}
          {isEdit
            ? "Edit Inventarisasi Hasil Pembangunan"
            : "Tambah Inventarisasi Hasil Pembangunan"}{" "}
        </h5>
        <hr />
        <FormC onSubmit={handleSubmit}>
          <div className="row">
            {/* Nama */}
            <InputC
              type="text"
              col="col-md-6 mb-3"
              label="Nama Aset"
              name="nama"
              value={formData.nama}
              onChange={handleChange}
              placeholder="Nama Aset..."
              error={errors?.nama}
            />

            {/* Jenis */}
            <SelectC
              col="col-6 mb-3"
              label="Jenis Aset"
              name="jenis"
              onChange={handleChange}
              value={formData.jenis}
              error={errors?.jenis}
            >
              <option value="Tanah">Tanah</option>
              <option value="Bangunan">Bangunan</option>
              <option value="Peralatan">Peralatan</option>
              <option value="Kendaraan">Kendaraan</option>
            </SelectC>

            {/* Lokasi */}
            <InputC
              type="text"
              col="col-md-6 mb-3"
              label="Lokasi "
              name="lokasi"
              value={formData.lokasi}
              onChange={handleChange}
              placeholder="Lokasi ..."
              error={errors?.lokasi}
            />

            {/* Tanggal Perolehan (tgl) */}
            <InputC
              type="date"
              col="col-md-6 mb-3"
              label="Tanggal Selesai"
              name="tgl"
              value={formData.tgl}
              onChange={handleChange}
              error={errors?.tgl}
            />

            {/* Anggaran (nilai) */}
            <InputC
              type="text"
              col="col-md-6 mb-3"
              label="Anggaran"
              name="anggaran"
              value={formData.anggaran}
              onChange={handleChange}
              placeholder="Anggaran..."
              error={errors?.anggaran}
            />

            {/* Kondisi Fisik */}
            <SelectC
              col="col-6 mb-3"
              label="Kondisi Fisik"
              name="kondisi"
              onChange={handleChange}
              value={formData.kondisi}
              error={errors?.kondisi}
            >
              <option value="Baik">Baik</option>
              <option value="Cukup">Cukup</option>
              <option value="Rusak Ringan">Rusak Ringan</option>
              <option value="Rusak Berat">Rusak Berat</option>
              <option value="Tidak Layak Pakai">Tidak Layak Pakai</option>
              <option value="Baru">Baru</option>
              <option value="Bekas">Bekas</option>
            </SelectC>

            {/* Sumber Dana */}
            <InputC
              type="text"
              col="col-md-6 mb-3"
              label="Sumber Dana"
              name="sumber"
              value={formData.sumber}
              onChange={handleChange}
              placeholder="Sumber Perolehan..."
              error={errors?.sumber}
            />

            {/* Volume (luas or another relevant field) */}
            <InputC
              type="text"
              col="col-md-6 mb-3"
              label="Volume"
              name="volume"
              value={formData.volume}
              onChange={handleChange}
              placeholder="Volume..."
              error={errors?.volume}
            />

            {/* Nomor (e.g., Serial Number, ID) */}
            <InputC
              type="text"
              col="col-md-6 mb-3"
              label="Nomor"
              name="nomor"
              value={formData.nomor}
              onChange={handleChange}
              placeholder="Nomor..."
              error={errors?.nomor}
            />

            {/* Pemilik (Owner) */}
            <InputC
              type="text"
              col="col-md-6 mb-3"
              label="Pemilik"
              name="pemilik"
              value={formData.pemilik}
              onChange={handleChange}
              placeholder="Pemilik..."
              error={errors?.pemilik}
            />
            <InputC
              type="text"
              col="col-md-6 mb-3"
              label="Nilai Aset"
              name="nilai"
              value={formData.nilai}
              onChange={handleChange}
              placeholder="Nilai Aset..."
              error={errors?.nilai}
            />
            {/* Status Kepemilikan */}
            <SelectC
              col="col-6 mb-3"
              label="Status Kepemilikan"
              name="status"
              onChange={handleChange}
              value={formData.status}
              error={errors?.status}
            >
              <option value="Milik Desa">Milik Desa</option>
              <option value="Hak Guna">Hak Guna</option>
              <option value="Sewa">Sewa</option>
              <option value="Pinjam Pakai">Pinjam Pakai</option>
              <option value="Hibah">Hibah</option>
              <option value="Kerjasama Operasional">
                Kerjasama Operasional
              </option>
              <option value="Milik Pemerintah">Milik Pemerintah</option>
              <option value="Milik Masyarakat">Milik Masyarakat</option>
            </SelectC>

            {/* Keterangan (ket) */}
            <InputC
              type="text"
              col="col-md-6 mb-3"
              label="Keterangan"
              name="ket"
              value={formData.ket}
              onChange={handleChange}
              placeholder="Keterangan..."
              error={errors?.ket}
            />

            {/* Dokumen (File Upload for Document) */}
            <InputC
              type="text"
              col="col-md-6 mb-3"
              label="Penanggung Jawab"
              name="penanggungjawab"
              value={formData.penanggungjawab}
              onChange={handleChange}
              placeholder="Penanggung Jawab..."
              error={errors?.penanggungjawab}
            />
            <InputC
              type="file"
              col="col-md-6"
              label="Upload Dokumen"
              name="dokumen"
              onChange={handleChange}
            />
            {image && (
              <img
                src={image}
                alt="preview"
                style={{ width: "10%", height: "auto" }}
              />
            )}
            {isEdit && (
              <>
                <InputC type="hidden" name="dokumen" value={formData.dokumen} />
                <img
                  src={`${process.env.REACT_APP_API_IMAGE}/${formData.dokumen}`}
                  alt="preview"
                  style={{ width: "10%", height: "auto" }}
                />
              </>
            )}
          </div>

          <button
            type="submit"
            className="btn btn-primary btn-sm me-2 mt-3"
            disabled={loading}
          >
            {loading ? "Loading..." : "Simpan"}
          </button>
          <Link to="/ihp" className="btn btn-danger btn-sm mt-3">
            Kembali
          </Link>
        </FormC>
      </LayoutBackend>
    </>
  );
};

export default AksiIhpPage;
