export interface Aparat {
  id_aparat: number;
  nama: string;
  tempat: string;
  tgl: string;
  sk: string;
  hp: string;
  tgl_berhenti: string;
}

export const fetchAparat = async (): Promise<Aparat[]> => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/aparat/${process.env.REACT_APP_TOKEN}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    }
  );
  const data = await response.json();

  return data;
};

export const filterData = (data: Aparat[], search: string): Aparat[] => {
  return data.filter((aparat: Aparat) => {
    const nomor = aparat.nama ? aparat.nama.toLowerCase() : "";
    const lowerCaseSearch = search.toLowerCase();

    return nomor.includes(lowerCaseSearch);
  });
};
// Fungsi untuk menghitung total halaman
export const getTotalPages = (
  filteredData: Aparat[],
  showCount: number
): number => {
  return Math.ceil(filteredData.length / showCount);
};

// Fungsi untuk mendapatkan data berdasarkan halaman saat ini
export const getPaginatedData = (
  filteredData: Aparat[],
  currentPage: number,
  showCount: number
): Aparat[] => {
  return filteredData.slice(
    (currentPage - 1) * showCount,
    currentPage * showCount
  );
};

export const fetchEditSkKades = async (id: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/edit_aparat/${id}/${process.env.REACT_APP_TOKEN}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    );
    console.log(response);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error("Error fetching form data:", error);
    throw error;
  }
};

export const validateFormData = (formData: any) => {
  const errors: any = {};

  // Validasi untuk setiap field
  if (!formData.nama) errors.nama = "Nama Lengkap harus diisi!";
  if (!formData.jk) errors.jk = "Jenis kelamin harus diisi!";
  if (!formData.tempat) errors.tempat = "Tempat Lahir harus diisi!";
  if (!formData.tgl) errors.tgl = "Tanggal Lahir harus diisi!";
  if (!formData.sk) errors.sk = "No SK Pengangkatan harus diisi!";
  if (!formData.hp) errors.hp = "No HP harus diisi!";
  if (!formData.tgl_berhenti)
    errors.tgl_berhenti = "Tanggal Berhenti harus diisi!";

  return errors;
};

export const submitFormData = async (formData: any, isEdit: boolean) => {
  const url = isEdit
    ? `${process.env.REACT_APP_API_URL}/update_aparat`
    : `${process.env.REACT_APP_API_URL}/maparat`;

  try {
    // Check if formData is already FormData, else convert it
    const body = formData instanceof FormData ? formData : new FormData();
    if (!(formData instanceof FormData)) {
      for (const key in formData) {
        if (Object.prototype.hasOwnProperty.call(formData, key)) {
          body.append(key, formData[key]);
        }
      }
    }

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: body,
    });

    const responseText = await response.text(); // Get response text for more information
    if (!response.ok) {
      console.error("Error response:", responseText);
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    console.log("Form data submitted successfully!");
  } catch (error) {
    console.error("Error submitting form data:", error);
    throw error;
  }
};
