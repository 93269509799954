import { Navigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchEditIhp, Ihp } from "./Controller";
import { Link } from "react-router-dom";
import LayoutBackend from "../../../../components/layouts/backend/Main";
import { useStateContext } from "../../../../middleware";

const DetailIhpPage = () => {
  const { id } = useParams();
  const [data, setData] = useState<Ihp>();
  const [loading, setLoading] = useState(true);
  const { token } = useStateContext();
  // const { token } = useStateContext();
  // Mengambil data dari API
  useEffect(() => {
    document.title = "Detail Data Anggota";
    const loadData = async () => {
      try {
        const data = await fetchEditIhp(window.location.pathname.split("/")[2]);
        setData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [data]);
  if (!token) {
    return <Navigate to="/login" />;
  }
  return (
    <>
      <LayoutBackend>
        <div className="row">
          <div className="col-12 col-lg-8">
            <div className="card shadow-sm border-0">
              <div className="card-body">
                <h5 className="mb-0">Informasi Inventaris</h5>
                <hr />
                <div className="card shadow-none border">
                  <div className="card-body">
                    {loading ? (
                      <div>Loading...</div>
                    ) : (
                      <>
                        <table className="table table-hover table-sm table-striped table-bordered">
                          <tbody>
                            <tr>
                              <td>Nama Kekayaan</td>
                              <td>{data?.nama}</td>
                            </tr>
                            <tr>
                              <td>Jenis Kekayaan</td>
                              <td>{data?.jenis}</td>
                            </tr>
                            <tr>
                              <td>Lokasi</td>
                              <td>{data?.lokasi}</td>
                            </tr>
                            <tr>
                              <td>Tanggal Perolehan</td>
                              <td>{data?.tgl}</td>
                            </tr>
                            <tr>
                              <td>Anggaran</td>
                              <td>{data?.anggaran}</td>
                            </tr>
                            <tr>
                              <td>Kondisi Barang</td>
                              <td>{data?.kondisi}</td>
                            </tr>
                            <tr>
                              <td>Sumber Dana</td>
                              <td>{data?.sumber}</td>
                            </tr>
                            <tr>
                              <td>Volume</td>
                              <td>{data?.volume}</td>
                            </tr>
                            <tr>
                              <td>Nomor Dokumen</td>
                              <td>{data?.nomor}</td>
                            </tr>
                            <tr>
                              <td>Pemilik</td>
                              <td>{data?.pemilik}</td>
                            </tr>
                            <tr>
                              <td>Nilai Kekayaan</td>
                              <td>{data?.nilai}</td>
                            </tr>
                            <tr>
                              <td>Status Kepemilikan</td>
                              <td>{data?.status}</td>
                            </tr>
                            <tr>
                              <td>Keterangan</td>
                              <td>{data?.ket}</td>
                            </tr>
                            <tr>
                              <td>Penanggung Jawab</td>
                              <td>{data?.penanggungjawab}</td>
                            </tr>
                          </tbody>
                        </table>
                      </>
                    )}
                  </div>
                </div>
                <Link to="/ihp" className="btn btn-sm btn-outline-danger">
                  Kembali
                </Link>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="card shadow-sm border-0 overflow-hidden">
              <div className="card-body">
                <div className="profile-avatar text-center">
                  <h5 className="text-uppercase">Dokumentasi</h5>
                  {data?.dokumen !== null ? (
                    <>
                      <Link
                        to={`${process.env.REACT_APP_API_IMAGE}/${data?.dokumen}`}
                        target="_blank"
                      >
                        <img
                          src={`${process.env.REACT_APP_API_IMAGE}/${data?.dokumen}`}
                          width={120}
                          height={120}
                          alt=""
                        />
                      </Link>
                    </>
                  ) : (
                    <>
                      <img src="/default.jpg" width={120} height={120} alt="" />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutBackend>
    </>
  );
};

export default DetailIhpPage;
