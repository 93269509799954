import { useEffect, useState } from "react";
import LayoutBackend from "../../../components/layouts/backend/Main";
import InputC from "../../../components/layouts/backend/Input";
import SelectC from "../../../components/layouts/backend/Select";
import {
  fetchEditPenduduk,
  submitFormData,
  validateFormData,
} from "./Controller";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useStateContext } from "../../../middleware";

const AksiPenduduk = () => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useStateContext();
  const [formData, setFormData] = useState({
    nama: "",
    nik: "",
    no_urut: window.location.pathname === `/tambah_kk/${id}` ? id : "",
    ke_akte: "",
    jenis: "",
    tempat: "",
    tgl: "",
    darah: "",
    alamat: "",
    rt: "",
    desa: "",
    ke_ktp: "",
    agama: "",
    pend_saatini: "",
    kwn: "",
    pekerjaan: "",
    ayah: "",
    ibu: "",
    pnd_terakhir: "",
    shdrt: "",
    kondisi_peker: "",
    sumber: "",
    bansos: "",
    bpjs: "",
    status_nikah: "", // Renamed from status
    tgl_kawin: "",
    pemilik_aktenikah: "",
    pemilik_aktecerai: "",
    status_domis: "",
    ket_domis: "",
    mutasi: "",
    tujuan_mutasi: "",
    ket_mutasi: "",
    sebab_mutasi: "",
    tgl_mutasi: "",
    sehat: "",
    penyakit: "",
    disabilitas: "",
    yatim: "",
    status_rumah: "",
    status_lahan: "",
    layak: "",
    penerangan: "",
    sumber_air: "",
    jamban: "",
    bpjs_kesehatan: "",
    user_id: 1,
  });

  const isEdit = window.location.pathname.includes("tambah_kk")
    ? false
    : Boolean(id);
  console.log(isEdit);
  useEffect(() => {
    document.title = isEdit ? "Edit Penduduk" : "Tambah Penduduk";
    if (id && window.location.pathname.includes("edit_penduduk")) {
      fetchEditPenduduk(id)
        .then((data) => setFormData(data))
        .catch((error) => console.error("Error:", error));
    }
  }, [id, isEdit]);
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, files } = e.target as HTMLInputElement;

    if (files && files.length > 0) {
      const file = files[0];
      if (file.size > 2 * 1024 * 1024) {
        // Memeriksa apakah ukuran file melebihi 2MB
        alert("Ukuran file tidak boleh melebihi 2MB!");
        return; // Menghentikan pemrosesan lebih lanjut
      }

      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const validationErrors = validateFormData(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setLoading(false);
      return;
    }
    try {
      await submitFormData(formData, isEdit);
      navigate("/penduduk", {
        state: {
          message: isEdit
            ? "Data berhasil diperbarui!"
            : "Data berhasil ditambahkan!",
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  if (!token) {
    return <Navigate to="/login" />;
  }
  return (
    <>
      <LayoutBackend>
        <div className="card">
          <div className="card-body">
            <ul className="nav nav-tabs nav-danger" role="tablist">
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link active"
                  data-bs-toggle="tab"
                  href="#dangerhome"
                  role="tab"
                  aria-selected="true"
                >
                  <div className="d-flex align-items-center">
                    <div className="tab-icon">
                      <i className="bx bx-user font-18 me-1" />
                    </div>
                    <div className="tab-title">Biodata</div>
                  </div>
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  href="#dangerprofile"
                  role="tab"
                  aria-selected="false"
                >
                  <div className="d-flex align-items-center">
                    <div className="tab-icon">
                      <i className="bx bx-user-pin font-18 me-1" />
                    </div>
                    <div className="tab-title">Tambahan</div>
                  </div>
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  href="#dangercontact"
                  role="tab"
                  aria-selected="false"
                >
                  <div className="d-flex align-items-center">
                    <div className="tab-icon">
                      <i className="bx bx-check font-18 me-1" />
                    </div>
                    <div className="tab-title">Finish</div>
                  </div>
                </a>
              </li>
            </ul>
            <form onSubmit={handleSubmit}>
              <div className="tab-content py-3">
                <div
                  className="tab-pane fade show active"
                  id="dangerhome"
                  role="tabpanel"
                >
                  <div className="row g-3">
                    <InputC
                      type="text"
                      label="Nama"
                      name="nama"
                      col="col-6"
                      value={formData.nama}
                      onChange={handleChange}
                      placeholder="Masukkan Nama Lengkap..."
                      error={errors?.nama}
                    />

                    <InputC
                      type="number"
                      label="NIK"
                      name="nik"
                      col="col-3"
                      value={formData.nik}
                      onChange={handleChange}
                      placeholder="Nomor Induk Kependudukan..."
                      error={errors?.nik}
                    />
                    {id === null ||
                    window.location.pathname === `/edit_penduduk/${id}` ||
                    window.location.pathname === `/tambah_penduduk` ? (
                      <>
                        <InputC
                          type="number"
                          label="No Kartu Keluarga"
                          name="no_urut"
                          col="col-3"
                          value={formData.no_urut}
                          onChange={handleChange}
                          placeholder="No Kartu Keluarga..."
                        />
                      </>
                    ) : (
                      <>
                        <InputC
                          type="number"
                          label="No Kartu Keluarga"
                          name="no_urut"
                          col="col-3"
                          value={id}
                          onChange={handleChange}
                          placeholder="No Kartu Keluarga..."
                          disable={true}
                        />
                      </>
                    )}

                    <SelectC
                      label="Jenis Kelamin"
                      name="jenis"
                      col="col-6"
                      onChange={handleChange}
                      value={formData.jenis}
                      error={errors?.jenis}
                    >
                      <option value="1">Laki-laki</option>
                      <option value="2">Perempuan</option>
                    </SelectC>

                    <InputC
                      type="text"
                      col="col-3"
                      label="Tempat Lahir"
                      name="tempat"
                      value={formData.tempat}
                      onChange={handleChange}
                      placeholder="Tempat Lahir..."
                      error={errors?.tempat}
                    />

                    <InputC
                      col="col-3"
                      type="date"
                      label="Tanggal Lahir"
                      name="tgl"
                      value={formData.tgl}
                      onChange={handleChange}
                      placeholder="Tanggal Lahir..."
                      error={errors?.tgl}
                    />

                    <SelectC
                      col="col-3"
                      label="Kepemilikan Akte Kelahiran"
                      name="ke_akte"
                      onChange={handleChange}
                      value={formData.ke_akte}
                      error={errors?.ke_akte}
                    >
                      <option value="1">Ada</option>
                      <option value="2">Tidak Ada</option>
                    </SelectC>

                    <SelectC
                      col="col-3"
                      label="Kepemilikan KTP"
                      name="ke_ktp"
                      onChange={handleChange}
                      value={formData.ke_ktp}
                      error={errors?.ke_ktp}
                    >
                      <option value="1">Ada</option>
                      <option value="2">Tidak Ada</option>
                    </SelectC>

                    <SelectC
                      col="col-6"
                      label="Agama"
                      name="agama"
                      onChange={handleChange}
                      value={formData.agama}
                      error={errors?.agama}
                    >
                      <option value="Islam">Islam</option>
                      <option value="Kristen">Kristen</option>
                      <option value="Katolik">Katolik</option>
                      <option value="Hindu">Hindu</option>
                      <option value="Buddha">Buddha</option>
                      <option value="Konghucu">Konghucu</option>
                    </SelectC>

                    <SelectC
                      col="col-6"
                      label="Pendidikan Terakhir (tamat)"
                      name="pnd_terakhir"
                      onChange={handleChange}
                      value={formData.pnd_terakhir}
                      error={errors?.pnd_terakhir}
                    >
                      <option value="SD/SEDERAJAT">SD/SEDERAJAT</option>
                      <option value="SMP/MTS">SMP/MTS</option>
                      <option value="SMA/SMK">SMA/SMK</option>
                      <option value="D1">D1</option>
                      <option value="D2">D2</option>
                      <option value="D3">D3</option>
                      <option value="S1">S1</option>
                      <option value="S2">S2</option>
                      <option value="S3">S3</option>
                    </SelectC>

                    <SelectC
                      col="col-6"
                      label="Pendidikan saat ini (yang sedang ditempuh)"
                      name="pend_saatini"
                      onChange={handleChange}
                      value={formData.pend_saatini}
                      error={errors?.pend_saatini}
                    >
                      <option value="SD/SEDERAJAT">SD/SEDERAJAT</option>
                      <option value="SMP/MTS">SMP/MTS</option>
                      <option value="SMA/SMK">SMA/SMK</option>
                      <option value="D1">D1</option>
                      <option value="D2">D2</option>
                      <option value="D3">D3</option>
                      <option value="S1">S1</option>
                      <option value="S2">S2</option>
                      <option value="S3">S3</option>
                    </SelectC>

                    <SelectC
                      col="col-3"
                      label="Jenis Pekerjaan"
                      name="pekerjaan"
                      onChange={handleChange}
                      value={formData.pekerjaan}
                      error={errors?.pekerjaan}
                    >
                      <option value="Tidak Bekerja">Tidak Bekerja</option>
                      <option value="Pelajar/Mahasiswa">
                        Pelajar/Mahasiswa
                      </option>
                      <option value="PNS/TNI/POLRI">PNS/TNI/POLRI</option>
                      <option value="Karyawan Swasta">Karyawan Swasta</option>
                      <option value="Wiraswasta">Wiraswasta</option>
                      <option value="Petani">Petani</option>
                      <option value="Nelayan">Nelayan</option>
                      <option value="Buruh">Buruh</option>
                      <option value="Pensiunan">Pensiunan</option>
                      <option value="Lainnya">Lainnya</option>
                    </SelectC>

                    <SelectC
                      col="col-3"
                      label="Kondisi Pekerjaan"
                      name="kondisi_peker"
                      onChange={handleChange}
                      value={formData.kondisi_peker}
                      error={errors?.kondisi_peker}
                    >
                      <option value="1">Tetap</option>
                      <option value="2">Tidak Tetap</option>
                    </SelectC>

                    <InputC
                      col="col-6"
                      type="text"
                      label="Sumber Penghasilan"
                      name="sumber"
                      value={formData.sumber}
                      onChange={handleChange}
                      placeholder="Sumber Penghasilan..."
                      error={errors?.sumber}
                    />

                    <SelectC
                      col="col-6"
                      label="Bansos yang diterima"
                      name="bansos"
                      onChange={handleChange}
                      value={formData.bansos}
                      error={errors?.bansos}
                    >
                      <option value="Tidak Ada">Tidak Ada</option>
                      <option value="PKH">PKH</option>
                      <option value="BPNT">BPNT</option>
                      <option value="BST">BST</option>
                      <option value="Lainnya">Lainnya</option>
                    </SelectC>

                    <SelectC
                      col="col-6"
                      label="BPJS Ketenagakerjaan"
                      name="bpjs"
                      onChange={handleChange}
                      value={formData.bpjs}
                      error={errors?.bpjs}
                    >
                      <option value="1">Terdaftar</option>
                      <option value="2">Tidak Terdaftar</option>
                    </SelectC>

                    <SelectC
                      col="col-6"
                      label="Status Perkawinan"
                      name="status_nikah"
                      onChange={handleChange}
                      value={formData.status_nikah}
                      error={errors?.status_nikah}
                    >
                      <option value="Belum Menikah">Belum Menikah</option>
                      <option value="Menikah">Menikah</option>
                      <option value="Cerai Hidup">Cerai Hidup</option>
                      <option value="Cerai Mati">Cerai Mati</option>
                    </SelectC>
                    <InputC
                      col="col-6"
                      type="date"
                      label="Tanggal  Perkawinan"
                      name="tgl_kawin"
                      value={formData.tgl_kawin}
                      onChange={handleChange}
                    />
                    <SelectC
                      col="col-6"
                      label="Kepemilikan Akta Nikah"
                      name="pemilik_aktenikah"
                      onChange={handleChange}
                      value={formData.pemilik_aktenikah}
                      error={errors?.pemilik_aktenikah}
                    >
                      <option value="1">Ada</option>
                      <option value="2">Tidak Ada</option>
                    </SelectC>

                    <SelectC
                      col="col-6"
                      label="Kepemilikan Akta Cerai"
                      name="pemilik_aktecerai"
                      onChange={handleChange}
                      value={formData.pemilik_aktecerai}
                      error={errors?.pemilik_aktecerai}
                    >
                      <option value="1">Ada</option>
                      <option value="2">Tidak Ada</option>
                    </SelectC>

                    <SelectC
                      col="col-6"
                      label="Status Hubungan Dalam Keluarga"
                      name="shdrt"
                      onChange={handleChange}
                      value={formData.shdrt}
                      error={errors?.shdrt}
                    >
                      <option value="Kepala Keluarga">Kepala Keluarga</option>
                      <option value="Istri">Istri</option>
                      <option value="Anak">Anak</option>
                      <option value="Orang Tua">Orang Tua</option>
                      <option value="Mertua">Mertua</option>
                    </SelectC>

                    <InputC
                      type="text"
                      label="Kewarganegaraan"
                      name="kwn"
                      col="col-6"
                      value={formData.kwn}
                      onChange={handleChange}
                      placeholder="Kewarganegaraan..."
                      error={errors?.kwn}
                    />

                    <InputC
                      type="text"
                      col="col-6"
                      label="Nama Ayah"
                      name="ayah"
                      value={formData.ayah}
                      onChange={handleChange}
                      placeholder="Nama Ayah..."
                      error={errors?.ayah}
                    />

                    <InputC
                      col="col-6"
                      type="text"
                      label="Nama Ibu"
                      name="ibu"
                      value={formData.ibu}
                      onChange={handleChange}
                      placeholder="Nama Ibu..."
                      error={errors?.ibu}
                    />
                    <SelectC
                      col="col-6"
                      label="Golongan Darah"
                      name="darah"
                      onChange={handleChange}
                      value={formData.darah}
                      error={errors?.darah}
                    >
                      <option value="A">A</option>
                      <option value="B">B</option>
                      <option value="AB">AB</option>
                      <option value="O">O</option>
                    </SelectC>
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="dangerprofile"
                  role="tabpanel"
                >
                  <div className="row g-3">
                    <InputC
                      col="col-6"
                      type="text"
                      label="Alamat"
                      name="alamat"
                      value={formData.alamat}
                      onChange={handleChange}
                      placeholder="Alamat..."
                      error={errors?.alamat}
                    />

                    <InputC
                      col="col-6"
                      type="text"
                      label="RT/RW"
                      name="rt"
                      value={formData.rt}
                      onChange={handleChange}
                      placeholder="RT/RW..."
                      error={errors?.rt}
                    />

                    <InputC
                      col="col-6"
                      type="text"
                      label="Desa/Dusun"
                      name="desa"
                      value={formData.desa}
                      onChange={handleChange}
                      placeholder="Desa/Dusun..."
                      error={errors?.desa}
                    />

                    <SelectC
                      col="col-6"
                      label="Status Domisili"
                      name="status_domis"
                      onChange={handleChange}
                      value={formData.status_domis}
                      error={errors?.status_domis}
                    >
                      <option value="1">Tetap</option>
                      <option value="2">Sementara</option>
                    </SelectC>

                    <InputC
                      col="col-6"
                      type="text"
                      label="Keterangan Domisili"
                      name="ket_domis"
                      value={formData.ket_domis}
                      onChange={handleChange}
                      placeholder="Keterangan Domisili..."
                      error={errors?.ket_domis}
                    />

                    <SelectC
                      col="col-6"
                      label="Mutasi"
                      name="mutasi"
                      onChange={handleChange}
                      value={formData.mutasi}
                    >
                      <option value="Pindah Masuk">Pindah Masuk</option>
                      <option value="Pindah Keluar">Pindah Keluar</option>
                      <option value="Kematian">Kematian</option>
                      <option value="Perkawinan">Perkawinan</option>
                    </SelectC>
                    <InputC
                      col="col-6"
                      type="date"
                      label="Tanggal Mutasi"
                      name="tgl_mutasi"
                      value={formData.tgl_mutasi}
                      onChange={handleChange}
                    />
                    <InputC
                      col="col-6"
                      type="text"
                      label="Alamat Tujuan"
                      name="tujuan_mutasi"
                      placeholder="Alamat Tujuan..."
                      value={formData.tujuan_mutasi}
                      onChange={handleChange}
                    />
                    <InputC
                      col="col-6"
                      type="text"
                      label="Keterangan Mutasi"
                      name="ket_mutasi"
                      value={formData.ket_mutasi}
                      onChange={handleChange}
                      placeholder="Keterangan Mutasi..."
                    />

                    <InputC
                      col="col-6"
                      type="text"
                      label="Sebab Mutasi"
                      name="sebab_mutasi"
                      value={formData.sebab_mutasi}
                      onChange={handleChange}
                      placeholder="Sebab Mutasi..."
                    />

                    <InputC
                      col="col-6"
                      type="text"
                      label="Kondisi Kesehatan"
                      name="sehat"
                      value={formData.sehat}
                      onChange={handleChange}
                      error={errors?.sehat}
                      placeholder="Kondisi Kesehatan..."
                    />
                    <InputC
                      col="col-6"
                      type="text"
                      label="Penyakit yang diderita"
                      name="penyakit"
                      value={formData.penyakit}
                      onChange={handleChange}
                      error={errors?.penyakit}
                      placeholder="Penyakit yang diderita..."
                    />

                    <InputC
                      col="col-6"
                      type="text"
                      label="Disabilitas"
                      name="disabilitas"
                      value={formData.disabilitas}
                      onChange={handleChange}
                      placeholder="Disabilitas..."
                      error={errors?.disabilitas}
                    />

                    <SelectC
                      col="col-6"
                      label="Yatim Piatu"
                      name="yatim"
                      onChange={handleChange}
                      value={formData.yatim}
                      error={errors?.yatim}
                    >
                      <option value="Tidak Ada">Tidak Ada</option>
                      <option value="Yatim">Yatim</option>
                      <option value="Piatu">Piatu</option>
                      <option value="Yatim dan Piatu">Yatim dan Piatu</option>
                    </SelectC>

                    <SelectC
                      col="col-6"
                      label="BPJS Kesehatan"
                      name="bpjs_kesehatan"
                      onChange={handleChange}
                      value={formData.bpjs_kesehatan}
                      error={errors?.bpjs_kesehatan}
                    >
                      <option value="1">Terdaftar</option>
                      <option value="2">Tidak Terdaftar</option>
                    </SelectC>

                    <SelectC
                      col="col-6"
                      label="Status Rumah Tinggal"
                      name="status_rumah"
                      onChange={handleChange}
                      value={formData.status_rumah}
                      error={errors?.status_rumah}
                    >
                      <option value="Milik Sendiri">Milik Sendiri</option>
                      <option value="Kontrak">Kontrak</option>
                      <option value="Lainnya">Lainnya</option>
                    </SelectC>

                    <SelectC
                      col="col-6"
                      label="Status Lahan Rumah Tinggal"
                      name="status_lahan"
                      onChange={handleChange}
                      value={formData.status_lahan}
                      error={errors?.status_lahan}
                    >
                      <option value="Milik Sendiri">Milik Sendiri</option>
                      <option value="Sewa">Sewa</option>
                      <option value="Lainnya">Lainnya</option>
                    </SelectC>

                    <SelectC
                      col="col-6"
                      label="Layak/Tidak Layak Huni"
                      name="layak"
                      onChange={handleChange}
                      value={formData.layak}
                      error={errors?.layak}
                    >
                      <option value="1">Layak</option>
                      <option value="2">Tidak Layak</option>
                    </SelectC>

                    <SelectC
                      col="col-6"
                      label="Penerangan"
                      name="penerangan"
                      onChange={handleChange}
                      value={formData.penerangan}
                      error={errors?.penerangan}
                    >
                      <option value="Listrik PLN">Listrik PLN</option>
                      <option value="Listrik Non PLN">Listrik Non PLN</option>
                      <option value="Tidak Listrik">Tidak Listrik</option>
                    </SelectC>

                    <SelectC
                      col="col-6"
                      label="Sumber Air"
                      name="sumber_air"
                      onChange={handleChange}
                      value={formData.sumber_air}
                      error={errors?.sumber_air}
                    >
                      <option value="PDAM">PDAM</option>
                      <option value="Sumur">Sumur</option>
                      <option value="Air Kemasan">Air Kemasan</option>
                      <option value="Lainnya">Lainnya</option>
                    </SelectC>

                    <SelectC
                      col="col-6"
                      label="Jamban Sehat"
                      name="jamban"
                      onChange={handleChange}
                      value={formData.jamban}
                      error={errors?.jamban}
                    >
                      <option value="1">Ada</option>
                      <option value="2">Tidak Ada</option>
                    </SelectC>
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="dangercontact"
                  role="tabpanel"
                >
                  <div className="card-body">
                    <center>
                      <h3>
                        Pengisian Data Penduduk Telah Selesai{" "}
                        <i className="bi bi-check text-success me-6"></i>
                      </h3>
                      <h4>Silahkan Klik Tombol Simpan </h4>
                      <span className="text-danger mb-3">
                        Jika tidak ada perubahan pada halaman ini maka cek
                        kembali data yang sudah diinput
                      </span>
                      <br />
                      <button type="submit" className="btn btn-primary">
                        {loading ? "Loading..." : "Simpan"}
                      </button>
                    </center>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </LayoutBackend>
    </>
  );
};

export default AksiPenduduk;
