import { useEffect, useState } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import {
  fetchEditArtikel,
  fetchKategori,
  Kategori,
  submitFormData,
  validateFormData,
} from "./Controller";
import LayoutBackend from "../../../../components/layouts/backend/Main";
import InputC from "../../../../components/layouts/backend/Input";
import CKEditorComponent from "../../../../components/layouts/backend/Ckeditor";
import TextareaC from "../../../../components/layouts/backend/Textarea";
import SelectC from "../../../../components/layouts/backend/Select";
import { useStateContext } from "../../../../middleware";
const AksiArtikel = () => {
  const { id } = useParams();
  const isEdit = Boolean(id);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: "",
    kategori_id: "",
    kutip: "",
    status: "",
    deskripsi: "",
    user_id: 1,
    gambar: new File([], ""),
  });
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImagePreview] = useState<string | null>(null);
  const [kategori, setKategori] = useState<Kategori[]>([]);
  const { token } = useStateContext();
  useEffect(() => {
    if (id && window.location.pathname.includes("edit_artikel")) {
      fetchEditArtikel(id)
        .then((data) => {
          setFormData((prevData) => ({
            ...prevData,
            ...data,
            user_id: 1,
            // Make sure penulis is included here
          }));
        })
        .catch((error) => console.error("Error:", error));
    }
  }, [id, isEdit]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await fetchKategori();
        setKategori(data); // Set initial filtered data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    loadData();
  }, []);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, files } = e.target as HTMLInputElement;
    if (files && files.length > 0) {
      const file = files[0];
      if (file.size > 2 * 1024 * 1024) {
        // Memeriksa apakah ukuran file melebihi 2MB
        alert("Ukuran file tidak boleh melebihi 2MB!");
        return; // Menghentikan pemrosesan lebih lanjut
      }
      // Membuat URL sementara untuk prapratampilan gambar
      const imageUrl = URL.createObjectURL(file);
      setImagePreview(imageUrl);
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const handleEditorChange = (data: string) => {
    setFormData((prevData) => ({
      ...prevData,
      deskripsi: data,
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    const validationErrors = validateFormData(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setIsLoading(false);
      return;
    }
    console.log(formData);
    try {
      await submitFormData(formData, isEdit);
      navigate("/berita", {
        state: {
          message: isEdit
            ? "Data berhasil diperbarui!"
            : "Data berhasil ditambahkan!",
        },
      });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };
  if (!token) return <Navigate to="/login" />;
  return (
    <LayoutBackend>
      <div className="row">
        <form onSubmit={handleSubmit} className="row">
          <div className="col-md-8">
            <div className="card">
              <div className="card-body">
                <InputC
                  type="text"
                  col="col-md-12 mb-3"
                  label="Judul Berita"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  placeholder="Judul Berita..."
                  error={errors?.title}
                />
                <TextareaC
                  col="col-md-12 mb-3"
                  label="Kutipan"
                  name="kutip"
                  value={formData.kutip}
                  onChange={handleChange}
                  placeholder="Keyword..."
                  error={errors?.kutip}
                />
                <label htmlFor="" className="form-label">
                  {" "}
                  Berita
                </label>
                <CKEditorComponent
                  data={formData.deskripsi}
                  onChange={handleEditorChange}
                />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <SelectC
                  col="col-12 mb-3"
                  label="Kategori Berita"
                  name="kategori_id"
                  onChange={handleChange}
                  value={formData.kategori_id}
                  error={errors?.kategori_id}
                >
                  {kategori.map((item) => (
                    <option key={item.id_kategori} value={item.id_kategori}>
                      {item.nama}
                    </option>
                  ))}
                </SelectC>
                <SelectC
                  col="col-12 mb-3"
                  label="Status Berita"
                  name="status"
                  onChange={handleChange}
                  value={formData.status}
                  error={errors?.status}
                >
                  <option value="1">Publish</option>
                  <option value="2">Draft</option>
                </SelectC>
                <InputC
                  type="file"
                  col="col-md-12 mb-3"
                  label="Upload Gambar"
                  name="gambar"
                  onChange={handleChange}
                  error={errors?.gambar}
                />
                {image && (
                  <img
                    src={image}
                    alt="preview"
                    style={{ width: "20%", height: "auto" }}
                  />
                )}
                {isEdit && (
                  <>
                    <InputC
                      type="hidden"
                      name="gambar"
                      value={formData.gambar}
                    />
                    <img
                      src={`${process.env.REACT_APP_API_IMAGE}/${formData.gambar}`}
                      alt="preview"
                      style={{ width: "20%", height: "auto" }}
                      className="mb-3"
                    />
                  </>
                )}
                <br />
                <button
                  type="submit"
                  className="btn btn-primary btn-sm me-2 "
                  disabled={isLoading}
                >
                  {isLoading ? "Loading..." : "Simpan"}
                </button>
                <Link to="/berita" className="btn btn-danger btn-sm">
                  Kembali
                </Link>
              </div>
            </div>
          </div>
        </form>
      </div>
    </LayoutBackend>
  );
};

export default AksiArtikel;
