import { Navigate, useParams } from "react-router-dom";
import LayoutBackend from "../../../components/layouts/backend/Main";
import { useEffect, useState } from "react";
import {
  calculateAge,
  fetchDetailPenduduk,
  fetchKeluarga,
  Penduduk,
} from "./Controller";
import { Link } from "react-router-dom";
import { formatTanggalIndonesia } from "../../Helper";
import { useStateContext } from "../../../middleware";

const DetailPendudukPage = () => {
  const { id } = useParams();
  const [data, setData] = useState<Penduduk>();
  const [keluarga, setKeluarga] = useState<Penduduk[]>([]);
  const [loading, setLoading] = useState(true);
  const { token } = useStateContext();

  // const { token } = useStateContext();
  // Mengambil data dari API
  useEffect(() => {
    document.title = "Detail Data Anggota";
    const loadData = async () => {
      try {
        const data = await fetchDetailPenduduk(
          window.location.pathname.split("/")[2]
        );
        setData(data);

        const keluarga = await fetchKeluarga(data.no_urut);
        setKeluarga(keluarga);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [data, keluarga]);
  if (!token) {
    return <Navigate to="/login" />;
  }
  return (
    <>
      <LayoutBackend>
        <div className="row">
          <div className="col-12 col-lg-8">
            <div className="card shadow-sm border-0">
              <div className="card-body">
                <h5 className="mb-0">Informasi Penduduk</h5>
                <hr />
                <div className="card shadow-none border">
                  <div className="card-body">
                    {loading ? (
                      <div>Loading...</div>
                    ) : (
                      <>
                        <table className="table table-hover table-sm table-striped table-bordered">
                          <tbody>
                            <tr>
                              <td>Jenis Kelamin</td>{" "}
                              <td style={{ width: "50%" }}>
                                {" "}
                                {data?.jenis === 1
                                  ? "Laki-laki"
                                  : "Perempuan"}{" "}
                              </td>
                            </tr>
                            <tr>
                              <td>Tempat, Tanggal Lahir</td>
                              <td>
                                {data?.tempat},{" "}
                                {formatTanggalIndonesia(data?.tgl || "")}
                              </td>
                            </tr>
                            <tr>
                              <td>Umur</td>{" "}
                              <td> {calculateAge(data?.tgl || "")} </td>
                            </tr>
                            <tr>
                              <td>Kepemilikan Akte Kelahiran </td>{" "}
                              <td>
                                {" "}
                                {data?.ke_akte === 1 ? "Ada" : "Tidak Ada"}{" "}
                              </td>
                            </tr>
                            <tr>
                              <td>Kepemilikan KTP </td>{" "}
                              <td>
                                {" "}
                                {data?.ke_ktp === "1"
                                  ? "Ada"
                                  : "Tidak Ada"}{" "}
                              </td>
                            </tr>
                            <tr>
                              <td>Agama</td> <td>{data?.agama}</td>
                            </tr>
                            <tr>
                              <td>Pendidikan Terakhir(Tamat)</td>
                              <td>{data?.pnd_terakhir}</td>
                            </tr>
                            <tr>
                              <td>
                                Pendidikan saat ini (yang sedang ditempuh){" "}
                              </td>{" "}
                              <td>{data?.pend_saatini}</td>
                            </tr>
                            <tr>
                              <td>Jenis Pekerjaan</td>
                              <td>{data?.pekerjaan}</td>
                            </tr>
                            <tr>
                              <td>Kondisi Pekerjaan</td>
                              <td>
                                {data?.kondisi_peker === 1
                                  ? "Tetap"
                                  : "Tidak Tetap"}
                              </td>
                            </tr>
                            <tr>
                              <td>Sumber Penghasilan </td>
                              <td>{data?.sumber}</td>
                            </tr>
                            <tr>
                              <td>Bansos yang diterima </td>
                              <td>{data?.bansos}</td>
                            </tr>
                            <tr>
                              <td>BPJS Ketenagakerjaan </td>
                              <td>
                                {data?.bpjs === 1
                                  ? "Terdaftar"
                                  : "Tidak Terdaftar"}
                              </td>
                            </tr>
                            <tr>
                              <td>Status Perkawinan</td>{" "}
                              <td> {data?.status_nikah} </td>
                            </tr>
                            <tr>
                              <td>Tanggal Perkawinan </td>{" "}
                              <td>
                                {" "}
                                {formatTanggalIndonesia(data?.tgl_kawin || "")}
                              </td>
                            </tr>
                            <tr>
                              <td>Kepemilikan Akta Nikah </td>{" "}
                              <td>
                                {" "}
                                {data?.pemilik_aktenikah === "1"
                                  ? "Ada"
                                  : "Tidak Ada"}
                              </td>
                            </tr>
                            <tr>
                              <td>Kepemilikan Akta Cerai </td>{" "}
                              <td>
                                {" "}
                                {data?.pemilik_aktecerai === "1"
                                  ? "Ada"
                                  : "Tidak Ada"}
                              </td>
                            </tr>
                            <tr>
                              <td>Kewarganegaraan</td> <td>{data?.kwn}</td>
                            </tr>

                            <tr>
                              <td>Nama Ayah</td> <td>{data?.ayah}</td>
                            </tr>
                            <tr>
                              <td>Nama Ibu</td> <td>{data?.ibu}</td>
                            </tr>

                            <tr>
                              <td>Golongan Darah</td> <td>{data?.darah}</td>
                            </tr>
                            <tr>
                              <td>Alamat</td> <td>{data?.alamat}</td>
                            </tr>
                            <tr>
                              <td>Rt/Rw</td> <td>{data?.rt}</td>
                            </tr>
                            <tr>
                              <td>Desa</td> <td>{data?.desa}</td>
                            </tr>
                            <tr>
                              <td>Status Domisili </td>{" "}
                              <td>
                                {data?.status_domis === "1"
                                  ? "Tetap"
                                  : "Sementara"}
                              </td>
                            </tr>
                            <tr>
                              <td>Keterangan Domisili </td>{" "}
                              <td>{data?.ket_domis}</td>
                            </tr>

                            <tr>
                              <td>Mutasi</td>{" "}
                              <td>
                                {" "}
                                {data?.mutasi === "1"
                                  ? "Ada"
                                  : "Tidak Ada"}{" "}
                              </td>
                            </tr>
                            <tr>
                              <td>Keterangan Mutasi</td>{" "}
                              <td> {data?.ket_mutasi} </td>
                            </tr>
                            <tr>
                              <td>Sebab Mutasi</td>{" "}
                              <td> {data?.sebab_mutasi}</td>
                            </tr>
                            <tr>
                              <td>Tanggal Mutasi</td>{" "}
                              <td>
                                {" "}
                                {formatTanggalIndonesia(
                                  data?.tgl_mutasi || ""
                                )}{" "}
                              </td>
                            </tr>
                            <tr>
                              <td>Kondisi Kesehatan </td>
                              <td> {data?.sehat} </td>
                            </tr>
                            <tr>
                              <td>Penyakit yang diderita </td>{" "}
                              <td> {data?.penyakit} </td>
                            </tr>
                            <tr>
                              <td>Disabilitas</td> <td>{data?.disabilitas}</td>
                            </tr>
                            <tr>
                              <td>Yatim Piatu </td> <td> {data?.yatim} </td>
                            </tr>
                            <tr>
                              <td>BPJS Kesehatan </td>{" "}
                              <td>
                                {" "}
                                {data?.bpjs_kesehatan === "1"
                                  ? "Terdaftar"
                                  : "Tidak Terdaftar"}{" "}
                              </td>
                            </tr>
                            <tr>
                              <td>Nama Kepala Keluarga </td> <td> Ahmad </td>
                            </tr>
                            <tr>
                              <td>Status Rumah Tinggal </td>{" "}
                              <td> {data?.status_rumah} </td>
                            </tr>
                            <tr>
                              <td>Status Lahan Rumah Tinggal </td>{" "}
                              <td> {data?.status_lahan} </td>
                            </tr>
                            <tr>
                              <td>Layak/Tidak Layak Huni </td>{" "}
                              <td>
                                {" "}
                                {data?.layak === 1
                                  ? "Layak"
                                  : "Tidak Layak"}{" "}
                              </td>
                            </tr>
                            <tr>
                              <td>Penerangan </td> <td> {data?.penerangan} </td>
                            </tr>
                            <tr>
                              <td>Sumber Air Bersih </td>{" "}
                              <td>{data?.sumber_air}</td>
                            </tr>
                            <tr>
                              <td>Jamban Sehat </td>{" "}
                              <td>
                                {data?.jamban === 1 ? "Ada" : "Tidak Ada"}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="card shadow-sm border-0 overflow-hidden">
              <div className="card-body">
                <div className="profile-avatar text-center">
                  <img
                    src="/default.png"
                    className="rounded-circle shadow"
                    width={120}
                    height={120}
                    alt=""
                  />
                </div>
              </div>
              <ul className="list-group list-group-flush">
                {loading ? (
                  <p className="text-center">Loading ...</p>
                ) : (
                  <>
                    <li className="list-group-item d-flex justify-content-between align-items-center bg-transparent border-top">
                      NIK
                      <span className="badge bg-primary">{data?.nik}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center bg-transparent">
                      Nama Lengkap
                      <span className="badge bg-primary">{data?.nama}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center bg-transparent">
                      Status Hubungan Dalam Keluarga{" "}
                      <span className="badge bg-primary">{data?.shdrt}</span>
                    </li>
                  </>
                )}
              </ul>
            </div>
            <div className="card shadow-sm border-0 overflow-hidden">
              <div className="card-header">
                <h5 className="card-title">Data Keluarga</h5>
              </div>
              <ul className="list-group list-group-flush">
                {loading ? (
                  <p className="text-center">Loading ...</p>
                ) : (
                  <>
                    {keluarga?.map((item: any) => (
                      <li
                        key={item?.id}
                        className="list-group-item d-flex justify-content-between align-items-center bg-transparent"
                      >
                        <Link to={`/lihat_penduduk/${item?.id_pend}`}>
                          {item?.nama}{" "}
                        </Link>
                        <span className="badge bg-primary">{item?.shdrt}</span>
                      </li>
                    ))}
                  </>
                )}
              </ul>
            </div>
            <Link to={`/penduduk`} className="btn btn-danger">
              Kembali
            </Link>
          </div>
        </div>
      </LayoutBackend>
    </>
  );
};

export default DetailPendudukPage;
