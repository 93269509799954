import { useEffect, useState } from "react";
import {
  fetchEditEkspedisi,
  submitFormData,
  validateFormData,
} from "./Controller";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import LayoutBackend from "../../../../components/layouts/backend/Main";
import InputC from "../../../../components/layouts/backend/Input";
import FormC from "../../../../components/layouts/backend/Form";
import { useStateContext } from "../../../../middleware";

const AksiEkspedisi = () => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useStateContext();
  const [formData, setFormData] = useState({
    tgl_surat: "",
    nomor: "",
    penerima: "",
    alamat: "",
    perihal: "",
    tgl: "",
    ket: "",
    status: "",
    file: new File([], ""),
    paraf: new File([], ""),
  });

  const isEdit = window.location.pathname.includes("tambah_Ekspedisi")
    ? false
    : Boolean(id);
  useEffect(() => {
    document.title = isEdit ? "Edit Kekayaan Desa" : "Tambah Kekayaan Desa";
    if (id && window.location.pathname.includes("edit_ekspedisi")) {
      fetchEditEkspedisi(id)
        .then((data) => setFormData(data))
        .catch((error) => console.error("Error:", error));
    }
  }, [id, isEdit]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, files } = e.target as HTMLInputElement;
    if (files && files.length > 0) {
      const file = files[0];
      if (file.size > 2 * 1024 * 1024) {
        // Memeriksa apakah ukuran file melebihi 2MB
        alert("Ukuran file tidak boleh melebihi 2MB!");
        return; // Menghentikan pemrosesan lebih lanjut
      }
      // Membuat URL sementara untuk prapratampilan gambar

      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const validationErrors = validateFormData(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setLoading(false);
      return;
    }
    try {
      await submitFormData(formData, isEdit);
      navigate("/ekspedisi", {
        state: {
          message: isEdit
            ? "Data berhasil diperbarui!"
            : "Data berhasil ditambahkan!",
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  if (!token) return <Navigate to="/login" />;
  return (
    <>
      <LayoutBackend>
        <h5 className="card-title">
          {" "}
          {isEdit ? "Edit Surat Masuk" : "Tambah Surat Masuk"}{" "}
        </h5>
        <hr />
        <FormC onSubmit={handleSubmit}>
          <div className="row">
            <InputC
              col="col-6"
              label="Tanggal Surat"
              name="tgl_surat"
              type="date"
              onChange={handleChange}
              value={formData.tgl_surat}
              error={errors?.tgl_surat}
            />
            <InputC
              type="text"
              col="col-md-6 mb-3"
              label="Nomor Surat"
              name="nomor"
              value={formData.nomor}
              onChange={handleChange}
              placeholder="Nomor Surat..."
              error={errors?.nomor}
            />

            <InputC
              type="text"
              col="col-md-6 mb-3"
              label="Alamat Penerima"
              name="alamat"
              value={formData.alamat}
              onChange={handleChange}
              error={errors?.alamat}
              placeholder="Alamat Penerima..."
            />
            <InputC
              type="text"
              col="col-md-6 mb-3"
              label="Perihal"
              name="perihal"
              value={formData.perihal}
              onChange={handleChange}
              placeholder="Perihal ..."
              error={errors?.perihal}
            />
            <InputC
              type="date"
              col="col-md-6 mb-3"
              label="Tanggal Penerimaan"
              name="tgl"
              value={formData.tgl}
              placeholder="Tanggal Penerimaan..."
              onChange={handleChange}
              error={errors?.tgl}
            />
            <InputC
              type="text"
              col="col-md-6 mb-3"
              label="Keterangan"
              name="ket"
              value={formData.ket}
              onChange={handleChange}
              placeholder="Keterangan..."
              error={errors?.ket}
            />
            <InputC
              type="file"
              col="col-md-6"
              label="Paraf Petugas"
              name="paraf"
              onChange={handleChange}
            />
            {formData.status === "1" && (
              <InputC
                type="file"
                col="col-md-6"
                label="Scan Surat Masuk "
                name="file"
                onChange={handleChange}
              />
            )}

            {isEdit && (
              <>
                <InputC type="hidden" name="file" value={formData.file} />
                <InputC type="hidden" name="paraf" value={formData.paraf} />
                <div className="col-md-6">
                  <img
                    src={`${process.env.REACT_APP_API_IMAGE}/${formData.paraf}`}
                    alt="preview"
                    style={{ width: "20%", height: "auto" }}
                  />
                </div>
                {formData.status === "1" && (
                  <div className="col-md-6">
                    <img
                      src={`${process.env.REACT_APP_API_IMAGE}/${formData.file}`}
                      alt="preview"
                      style={{ width: "20%", height: "auto" }}
                    />
                  </div>
                )}
              </>
            )}
          </div>
          <button
            type="submit"
            className="btn btn-primary btn-sm me-2 mt-3"
            disabled={loading}
          >
            {loading ? "Loading..." : "Simpan"}
          </button>
          <Link to="/ekspedisi" className="btn btn-danger btn-sm mt-3">
            Kembali
          </Link>
        </FormC>
      </LayoutBackend>
    </>
  );
};

export default AksiEkspedisi;
