import { useEffect, useState } from "react";
import LayoutBackend from "../../../components/layouts/backend/Main";
import {
  fetchMutasi,
  filterData,
  Penduduk,
} from "../../master/penduduk/Controller";
import { Link, Navigate, useLocation } from "react-router-dom";
import { useStateContext } from "../../../middleware";

const MutasiPage = () => {
  const [data, setData] = useState<Penduduk[]>([]);
  const [filteredData, setFilteredData] = useState<Penduduk[]>([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState<string>("");
  const [showCount, setShowCount] = useState<number>(100);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const location = useLocation();
  const [message, setMessage] = useState(location.state?.message);
  const [formData, setFormData] = useState<any>({
    file: "",
  });
  const { token } = useStateContext();
  const updatePageData = (paginationData: any) => {
    setData(paginationData.data);
    setCurrentPage(paginationData.current_page);
    setTotalPages(paginationData.last_page);
    setLoading(false);
  };

  useEffect(() => {
    document.title = "Data Artikel";
    const loadData = async () => {
      try {
        const paginationData = await fetchMutasi(currentPage, showCount);
        updatePageData(paginationData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    loadData();
  }, [currentPage, showCount]);

  useEffect(() => {
    const result = filterData(data, search);
    setFilteredData(result);
  }, [data, search]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const handleShowCountChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setShowCount(Number(e.target.value));
    setCurrentPage(1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  if (!token) return <Navigate to="/login" />;

  return (
    <LayoutBackend>
      <>
        {message && (
          <div className="alert alert-success">
            {" "}
            <i className="fas fa-check"></i> {message}
          </div>
        )}
        <div className="col-sm-12">
          <div className="d-flex justify-content-between align-items-center">
            <h6 className="mb-0 text-uppercase">Data Mutasi Penduduk</h6>
          </div>
        </div>
        <hr />
        <div className="card">
          <div className="card-body">
            <div className="table-responsive">
              <div className="col-sm-12 mb-3">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="dataTables_length">
                    <label>
                      Show{" "}
                      <select
                        name="example_length"
                        aria-controls="example"
                        className="form-select form-select-sm"
                        value={showCount}
                        onChange={handleShowCountChange}
                      >
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </label>
                  </div>
                  <div className="dataTables_filter">
                    <label className="w-100">
                      Search:
                      <input
                        type="search"
                        className="form-control form-control-sm"
                        placeholder=""
                        aria-controls="example"
                        value={search}
                        onChange={handleSearchChange}
                      />
                    </label>
                  </div>
                </div>
              </div>
              <table
                id="example"
                className="table table-striped table-bordered"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Nama</th>
                    <th>Tanggal Mutasi</th>
                    <th>Asal</th>
                    <th>Alamat Tujuan</th>
                    <th>Sebab Mutasi</th>
                    <th>Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan={6} className="text-center">
                        Loading...
                      </td>
                    </tr>
                  ) : filteredData.length ? (
                    filteredData.map((penduduk, index) => (
                      <tr key={penduduk.id_pend}>
                        <td> {(currentPage - 1) * showCount + index + 1}</td>
                        <td>{penduduk.nama}</td>
                        <td>{penduduk.tgl_mutasi}</td>
                        <td>{penduduk.alamat}</td>
                        <td>{penduduk.tujuan_mutasi}</td>
                        <td>{penduduk.sebab_mutasi}</td>

                        <td>
                          <Link
                            to={`/lihat_penduduk/${penduduk.id_pend}`}
                            className="btn  btn-sm btn-warning m-1"
                          >
                            <i className="bi bi-eye"></i>
                          </Link>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={7} className="text-center">
                        Tidak ada data
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="d-flex justify-content-between align-items-center">
                <div
                  className="dataTables_info"
                  id="example2_info"
                  role="status"
                  aria-live="polite"
                >
                  Showing {(currentPage - 1) * showCount + 1} to{" "}
                  {Math.min(currentPage * showCount, filteredData.length)} of{" "}
                  {filteredData.length} entries
                </div>
                <div id="example_paginate">
                  <ul className="pagination">
                    <li
                      className={`paginate_button page-item previous ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={handlePrevPage}
                        disabled={currentPage === 1}
                      >
                        Prev
                      </button>
                    </li>
                    {[...Array(totalPages)].map((_, pageIndex) => (
                      <li
                        key={pageIndex}
                        className={`paginate_button page-item ${
                          currentPage === pageIndex + 1 ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => setCurrentPage(pageIndex + 1)}
                        >
                          {pageIndex + 1}
                        </button>
                      </li>
                    ))}
                    <li
                      className={`paginate_button page-item next ${
                        currentPage === totalPages ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                      >
                        Next
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </LayoutBackend>
  );
};

export default MutasiPage;
