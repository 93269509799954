import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DashboardPage from "./backend/Dashboard";
import PendudukPage from "./backend/master/penduduk/Penduduk";
import DetailPendudukPage from "./backend/master/penduduk/Detail";
import AksiPenduduk from "./backend/master/penduduk/Aksi";
import RekapitulasiPage from "./backend/administrasi/penduduk/Rekapitulasi";
import MutasiPage from "./backend/administrasi/penduduk/Mutasi";
import DomisiliPage from "./backend/administrasi/penduduk/Domisili";
import PerdesPage from "./backend/administrasi/umum/perdes/Data";
import AksiPerdes from "./backend/administrasi/umum/perdes/Aksi";
import SkKadesPage from "./backend/administrasi/umum/skkades/Data";
import AksiSkKades from "./backend/administrasi/umum/skkades/Aksi";
import AksiPerkades from "./backend/administrasi/umum/perkades/Aksi";
import PerkadesPage from "./backend/administrasi/umum/perkades/Data";
import AparatPage from "./backend/administrasi/umum/aparat/Data";
import AksiAparat from "./backend/administrasi/umum/aparat/Aksi";
import InventarisPage from "./backend/administrasi/umum/inventaris/Data";
import AksiInventaris from "./backend/administrasi/umum/inventaris/Aksi";
import KekayaanPage from "./backend/administrasi/umum/kekayaan/Data";
import AksiKekayaan from "./backend/administrasi/umum/kekayaan/Aksi";
import DetailInventarisPage from "./backend/administrasi/umum/inventaris/Detail";
import DetailKekayaanPage from "./backend/administrasi/umum/kekayaan/Detail";
import EkspedisiPage from "./backend/administrasi/umum/ekspedisi/Data";
import AksiEkspedisi from "./backend/administrasi/umum/ekspedisi/Aksi";
import DetailEkspedisiPage from "./backend/administrasi/umum/ekspedisi/Detail";
import SuratKeluarPage from "./backend/administrasi/umum/ekspedisi/SuratKeluar";
import BeritaPage from "./backend/administrasi/umum/berita/Artikel";
import AksiArtikel from "./backend/administrasi/umum/berita/Aksi";
import KategoriPage from "./backend/administrasi/umum/berita/kategori/Data";
import AksiKategori from "./backend/administrasi/umum/berita/kategori/Aksi";
import AksiIhpPage from "./backend/administrasi/pembangunan/ihp/Aksi";
import DetailIhpPage from "./backend/administrasi/pembangunan/ihp/Detail";
import LembagaPage from "./backend/administrasi/pembangunan/lkd/Lembaga";
import AksiLembagaPage from "./backend/administrasi/pembangunan/lkd/AksiLembaga";
import LkdPage from "./backend/administrasi/pembangunan/lkd/Data";
import AksiLkdPage from "./backend/administrasi/pembangunan/lkd/AksiLkd";
import LoginPage from "./backend/Login";
import RencanaKerjaPage from "./backend/administrasi/pembangunan/rencana/Data";
import AksiRencanaKerja from "./backend/administrasi/pembangunan/rencana/Aksi";
import KegiatanPembangunanPage from "./backend/administrasi/pembangunan/rencana/kegiatan/Data";
import AksiKegiatan from "./backend/administrasi/pembangunan/rencana/kegiatan/Aksi";
import DetailKegiatan from "./backend/administrasi/pembangunan/rencana/kegiatan/Detail";
import IhpPage from "./backend/administrasi/pembangunan/ihp/Data";
import { StateProvider } from "./middleware";
import NotifikasiPage from "./backend/administrasi/notifikasi/Data";
function App() {
  return (
    <StateProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/dashboard" element={<DashboardPage />} />

          <Route path="/penduduk" element={<PendudukPage />} />
          <Route path="/mutasi" element={<MutasiPage />} />
          <Route path="/domisili" element={<DomisiliPage />} />
          <Route path="/rekapitulasi" element={<RekapitulasiPage />} />
          <Route path="/lihat_penduduk/:id" element={<DetailPendudukPage />} />
          <Route path="/tambah_penduduk" element={<AksiPenduduk />} />
          <Route path="/edit_penduduk/:id" element={<AksiPenduduk />} />
          <Route path="/tambah_kk/:id" element={<AksiPenduduk />} />

          <Route path="/perdes" element={<PerdesPage />} />
          <Route path="/edit_perdes/:id" element={<AksiPerdes />} />
          <Route path="/tambah_perdes" element={<AksiPerdes />} />

          <Route path="/skkades" element={<SkKadesPage />} />
          <Route path="/edit_skkades/:id" element={<AksiSkKades />} />
          <Route path="/tambah_skkades" element={<AksiSkKades />} />

          <Route path="/perkades" element={<PerkadesPage />} />
          <Route path="/edit_perkades/:id" element={<AksiPerkades />} />
          <Route path="/tambah_perkades" element={<AksiPerkades />} />

          <Route path="/aparat" element={<AparatPage />} />
          <Route path="/edit_aparat/:id" element={<AksiAparat />} />
          <Route path="/tambah_aparat" element={<AksiAparat />} />

          <Route path="/inventaris" element={<InventarisPage />} />
          <Route path="/edit_inventaris/:id" element={<AksiInventaris />} />
          <Route
            path="/lihat_inventaris/:id"
            element={<DetailInventarisPage />}
          />
          <Route path="/tambah_inventaris" element={<AksiInventaris />} />

          <Route path="/lihat_kekayaan/:id" element={<DetailKekayaanPage />} />
          <Route path="/kekayaan" element={<KekayaanPage />} />
          <Route path="/edit_kekayaan/:id" element={<AksiKekayaan />} />
          <Route path="/tambah_kekayaan" element={<AksiKekayaan />} />

          <Route path="/ekspedisi" element={<EkspedisiPage />} />
          <Route path="/surat_keluar" element={<SuratKeluarPage />} />
          <Route
            path="/lihat_ekspedisi/:id"
            element={<DetailEkspedisiPage />}
          />
          <Route path="/edit_ekspedisi/:id" element={<AksiEkspedisi />} />

          <Route path="/berita" element={<BeritaPage />} />
          <Route path="/tambah_berita" element={<AksiArtikel />} />
          <Route path="/edit_artikel/:id" element={<AksiArtikel />} />
          <Route path="/kategori" element={<KategoriPage />} />
          <Route path="/tambah_kategori" element={<AksiKategori />} />
          <Route path="/edit_kategori/:id" element={<AksiKategori />} />

          <Route path="/rencana" element={<RencanaKerjaPage />} />
          <Route path="/tambah_rencana" element={<AksiRencanaKerja />} />
          <Route path="/kegiatan" element={<KegiatanPembangunanPage />} />
          <Route path="/tambah_kegiatan" element={<AksiKegiatan />} />
          <Route path="/edit_rencana/:id" element={<AksiRencanaKerja />} />
          <Route path="/edit_kegiatan/:id" element={<AksiKegiatan />} />
          <Route path="/detail_kegiatan/:id" element={<DetailKegiatan />} />

          <Route path="/ihp" element={<IhpPage />} />
          <Route path="/tambah_ihp" element={<AksiIhpPage />} />
          <Route path="/lihat_ihp/:id" element={<DetailIhpPage />} />
          <Route path="/edit_ihp/:id" element={<AksiIhpPage />} />

          <Route path="/lkd" element={<LkdPage />} />
          <Route path="/tambah_lkd" element={<AksiLkdPage />} />
          <Route path="/edit_lkd/:id" element={<AksiLkdPage />} />

          <Route path="/lembaga" element={<LembagaPage />} />
          <Route path="/tambah_lembaga" element={<AksiLembagaPage />} />
          <Route path="/edit_lembaga/:id" element={<AksiLembagaPage />} />

          <Route path="/notifikasi" element={<NotifikasiPage />} />
        </Routes>
      </Router>
    </StateProvider>
  );
}

export default App;
