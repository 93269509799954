import { Navigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchEditInventaris, Inventaris } from "./Controller";
import { Link } from "react-router-dom";
import LayoutBackend from "../../../../components/layouts/backend/Main";
import { useStateContext } from "../../../../middleware";

const DetailInventarisPage = () => {
  const { id } = useParams();
  const [data, setData] = useState<Inventaris>();
  const [keluarga, setKeluarga] = useState<Inventaris[]>([]);
  const [loading, setLoading] = useState(true);
  const { token } = useStateContext();
  // const { token } = useStateContext();
  // Mengambil data dari API
  useEffect(() => {
    document.title = "Detail Data Anggota";
    const loadData = async () => {
      try {
        const data = await fetchEditInventaris(
          window.location.pathname.split("/")[2]
        );
        setData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [data, keluarga]);
  if (!token) return <Navigate to="/login" />;
  return (
    <>
      <LayoutBackend>
        <div className="row">
          <div className="col-12 col-lg-12">
            <div className="card shadow-sm border-0">
              <div className="card-body">
                <h5 className="mb-0">Informasi Inventaris</h5>
                <hr />
                <div className="card shadow-none border">
                  <div className="card-body">
                    {loading ? (
                      <div>Loading...</div>
                    ) : (
                      <>
                        <table className="table table-hover table-sm table-striped table-bordered">
                          <tbody>
                            <tr>
                              <td>Nama Barang</td> <td>:</td>{" "}
                              <td> {data?.nama}</td>
                            </tr>
                            <tr>
                              <td>Jenis Barang</td> <td>:</td>
                              <td>{data?.jenis}</td>
                            </tr>
                            <tr>
                              <td>Merek / Model Barang </td> <td>:</td>{" "}
                              <td> {data?.merek}</td>
                            </tr>
                            <tr>
                              <td>Jumlah </td> <td>:</td>{" "}
                              <td> {data?.jumlah}</td>
                            </tr>
                            <tr>
                              <td>Tahun Perolehan</td> <td>:</td>{" "}
                              <td>{data?.tahun}</td>
                            </tr>
                            <tr>
                              <td>Sumber Perolehan</td> <td>:</td>
                              <td>{data?.sumber}</td>
                            </tr>
                            <tr>
                              <td>Kondisi Barang</td> <td>:</td>
                              <td>{data?.kondisi}</td>
                            </tr>
                            <tr>
                              <td>Haga Perolehan</td> <td>:</td>
                              <td>{data?.harga}</td>
                            </tr>
                            <tr>
                              <td>Lokasi Barang</td> <td>:</td>
                              <td>{data?.lokasi}</td>
                            </tr>
                            <tr>
                              <td>Keterangan Tambahan </td> <td>:</td>
                              <td>{data?.des}</td>
                            </tr>
                          </tbody>
                        </table>
                      </>
                    )}
                  </div>
                </div>
                <Link
                  to="/inventaris"
                  className="btn btn-sm btn-outline-danger"
                >
                  Kembali
                </Link>
              </div>
            </div>
          </div>
        </div>
      </LayoutBackend>
    </>
  );
};

export default DetailInventarisPage;
