import { useEffect, useState } from "react";
import {
  fetchEditRencana,
  submitFormData,
  validateFormData,
} from "./Controller";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import LayoutBackend from "../../../../../components/layouts/backend/Main";
import InputC from "../../../../../components/layouts/backend/Input";
import FormC from "../../../../../components/layouts/backend/Form";
import SelectC from "../../../../../components/layouts/backend/Select";
import { useStateContext } from "../../../../../middleware";

const AksiKegiatan = () => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useStateContext();
  const [formData, setFormData] = useState({
    uraian: "",
    anggaran: "",
    sumber: "",
    lokasi: "",
    jumlah: "",
    status: "",
    pelaksana: "",
    waktu: "",
    sasaran: "",
    user_id: 1,
  });

  const isEdit = window.location.pathname.includes("tambah_kegiatan")
    ? false
    : Boolean(id);
  useEffect(() => {
    document.title = isEdit
      ? "Edit Kegiatan Pembangunan"
      : "Tambah Kegiatan Pembangunan";
    if (id && window.location.pathname.includes("edit_kegiatan")) {
      fetchEditRencana(id)
        .then((data) => setFormData(data))
        .catch((error) => console.error("Error:", error));
    }
  }, [id, isEdit]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target as HTMLInputElement;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const validationErrors = validateFormData(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setLoading(false);
      return;
    }
    try {
      await submitFormData(formData, isEdit);
      navigate("/kegiatan", {
        state: {
          message: isEdit
            ? "Data berhasil diperbarui!"
            : "Data berhasil ditambahkan!",
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  if (!token) return <Navigate to="/login" />;
  return (
    <>
      <LayoutBackend>
        <h5 className="card-title">
          {" "}
          {isEdit
            ? "Edit Kegiatan Pembangunan"
            : "Tambah Kegiatan Pembangunan"}{" "}
        </h5>
        <hr />
        <FormC onSubmit={handleSubmit}>
          <div className="row">
            <InputC
              type="text"
              col="col-md-6 mb-3"
              label="Uraian"
              name="uraian"
              value={formData.uraian}
              onChange={handleChange}
              placeholder="Uraian..."
              error={errors?.uraian}
            />
            <InputC
              type="text"
              col="col-md-6 mb-3"
              label="Lokasi"
              name="lokasi"
              value={formData.lokasi}
              onChange={handleChange}
              placeholder="Lokasi..."
              error={errors?.lokasi}
            />
            <InputC
              type="text"
              col="col-md-6 mb-3"
              label="Anggaran"
              name="anggaran"
              value={formData.anggaran}
              onChange={handleChange}
              placeholder="Anggaran..."
              error={errors?.anggaran}
            />
            <InputC
              type="text"
              col="col-md-6 mb-3"
              label="Sumber Dana"
              name="sumber"
              value={formData.sumber}
              onChange={handleChange}
              placeholder="Sumber Dana..."
              error={errors?.sumber}
            />
            <InputC
              type="text"
              col="col-md-6 mb-3"
              label="Jumlah"
              name="jumlah"
              value={formData.jumlah}
              onChange={handleChange}
              placeholder="Jumlah..."
              error={errors?.jumlah}
            />
            <InputC
              type="date"
              col="col-md-6 mb-3"
              label="Waktu Pelaksanaan"
              name="waktu"
              value={formData.waktu}
              onChange={handleChange}
              error={errors?.waktu}
            />
            <InputC
              type="text"
              col="col-md-6 mb-3"
              label="Pelaksana Kegiatan"
              name="pelaksana"
              placeholder="Pelaksana Kegiatan..."
              value={formData.pelaksana}
              onChange={handleChange}
              error={errors?.pelaksana}
            />
            <InputC
              type="text"
              col="col-md-6 mb-3"
              label="Penerima Manfaat"
              name="sasaran"
              placeholder="Penerima Manfaat..."
              value={formData.sasaran}
              onChange={handleChange}
              error={errors?.sasaran}
            />
            <SelectC
              col="col-6 mb-3"
              label="Status Kegiatan Pembangunan"
              name="status"
              onChange={handleChange}
              value={formData.status}
              error={errors?.status}
            >
              <option value="Dalam Perencanaan">Dalam Perencanaan</option>
              <option value="Sudah Dimulai">Sudah Dimulai</option>
              <option value="Sedang Berjalan">Sedang Berjalan</option>
              <option value="Sudah Selesai">Sudah Selesai</option>
            </SelectC>
          </div>
          <button
            type="submit"
            className="btn btn-primary btn-sm me-2"
            disabled={loading}
          >
            {loading ? "Loading..." : "Simpan"}
          </button>
          <Link to="/kegiatan" className="btn btn-danger btn-sm">
            Kembali
          </Link>
        </FormC>
      </LayoutBackend>
    </>
  );
};

export default AksiKegiatan;
