const TabelC = (props: any) => {
  const { children, value, onChange, search, handleSearchChange } = props;
  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="table-responsive">
            <div className="col-sm-12 mb-3">
              <div className="d-flex justify-content-between align-items-center">
                <div className="dataTables_length">
                  <label>
                    Show{" "}
                    <select
                      name="example_length"
                      aria-controls="example"
                      className="form-select form-select-sm"
                      value={value}
                      onChange={onChange}
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                  </label>
                </div>
                <div className="dataTables_filter">
                  <label className="w-100">
                    Search:
                    <input
                      type="search"
                      className="form-control form-control-sm"
                      placeholder=""
                      aria-controls="example"
                      value={search}
                      onChange={handleSearchChange}
                    />
                  </label>
                </div>
              </div>
            </div>
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default TabelC;
