import { Link } from "react-router-dom";

const LinkC = (props: any) => {
  const { href, children, icon } = props;
  return (
    <>
      {" "}
      <li>
        <Link to={href}>
          <div className="parent-icon">
            <i className={icon} />
          </div>
          <div className="menu-title">{children} </div>
        </Link>
      </li>
    </>
  );
};

export default LinkC;
