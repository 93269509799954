import LabelC from "./Label";

const InputC = (props: any) => {
  const {
    type,
    name,
    placeholder,
    value,
    onChange,
    label,
    col,
    error,
    disable,
  } = props;
  return (
    <>
      <div className={col}>
        <LabelC>{label} </LabelC>
        <input
          type={type}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          className="form-control"
          disabled={disable}
        />
        <span className="text-danger">{error}</span>
      </div>
    </>
  );
};
export default InputC;
